import parse from 'html-react-parser'
import React from 'react'
import { Button, LinkButton, Modal } from '../../../../../../components'
import i18n from '../../../../../../i18n'
import ROUTES from '../../../../../../routes/common/constants'

interface Props {
  onClose: () => void
  isOpen: boolean
}

const PhoneNumberModal = ({ onClose, isOpen }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition phone-number-notice-modal" data-testid="model-phone-is-2fa">
      <div data-testid={'key-definition-modal'}>
        <h4 className="title" data-testid={'key-title'}>
          {parse(i18n.t('wallet-profile-modal-phone-number-title'))}
        </h4>
        <div className="subtitle" data-testid={'key-subtitle'}>
          {parse(i18n.t('wallet-profile-modal-phone-number-desc'))}
        </div>
        <div className="btn-group">
          <Button dataTestId="Button-secondary" className={'cancel-btn inverted'} onClick={onClose} text={i18n.t('cancel')} />
          <LinkButton url={ROUTES.Change2FARoute} className="primary-btn focus-visible" text={i18n.t('wallet-profile-change-authentication-method')} />
        </div>
      </div>
    </Modal>
  )
}

export default PhoneNumberModal
