import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { Layout, Text, Title } from '../../../components'
import AppAuthSetupStep2 from '../../../components/appAuthSetup/step2'
import { PageI } from '../../../components/ComponentInterface'
import i18n from '../../../i18n'
import useProfile from '../../Wallet/pages/profile/hooks/useProfile'
import useChange2FA from './hooks/useChange2FA'

const Change2FAAppQRPage = ({ goBackLink, successFunc }: PageI) => {
  const [authenticatorSecret, setAuthenticatorSecret] = useState('')
  const { submit: requestQRSecret, isLoading: isQRLoading, errorObj } = useChange2FA({ method: 'TOTP' })
  const { profileData, getProfile } = useProfile()

  const onQRFetchSuccess = async (response: any) => {
    setAuthenticatorSecret(response?.data?.secret)
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!authenticatorSecret && !errorObj.error) {
      requestQRSecret({ onSuccess: onQRFetchSuccess })
    }
    // eslint-disable-next-line
  }, [authenticatorSecret, errorObj])

  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back')} title={i18n.t('change-auth-title')} className={'change-2FA authenticator-app-flow step-2'}>
      <Title title={i18n.t('setup-authenticator-app-title')} />
      {!!errorObj?.error && <Text className="error">{parse(i18n.t(errorObj.error))}</Text>}
      <AppAuthSetupStep2 isQRLoading={isQRLoading} authenticatorSecret={authenticatorSecret} identifier={profileData?.email} onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default Change2FAAppQRPage
