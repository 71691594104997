import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import useScrollToTop from '../common/hooks/useScrollToTop'

import LocalStorage from '../services/LocalStorage'
import useSessionValidation from '../common/hooks/useSessionValidation'
import SessionModal from '../components/session/SessionModal'
import useHandleLogout from '../common/hooks/useHandleLogout'

export const ProtectedWalletRoute = ({ component: Component, ...rest }: any) => {
  useScrollToTop()
  const { checkIDStoreToken } = useSessionValidation()
  const { logoutMyPei } = useHandleLogout()

  const sessionWallet = LocalStorage.get('session')

  useEffect(() => {
    checkIDStoreToken()

    if (!sessionWallet) logoutMyPei()

    // eslint-disable-next-line
  }, [sessionWallet])

  return (
    <>
      <SessionModal />
      <Route
        {...rest}
        render={() => {
          return sessionWallet && <Component />
        }}
      />
    </>
  )
}
