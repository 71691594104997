import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { RecaptchaI } from './ComponentInterface';

const ReCaptcha = ({ recaptchaRef, recaptchaKey, onChange, onExpired, asyncScriptOnLoad }: RecaptchaI) => (
    <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaKey || 'key'}
        onChange={onChange}
        size="invisible"
        onExpired={onExpired}
        asyncScriptOnLoad={asyncScriptOnLoad}
    />
);

export default ReCaptcha;
