import React from 'react'

export const validateTextInput = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number, minLength: number) => {
  return Boolean(event.target.value && event.target.value.length >= minLength && event.target.value.length <= maxLength).valueOf()
}

export const validateNameInput = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number, minLength: number) => {
  const regex = /^[a-zA-ZÀ-ÿ\s-]+$/
  return Boolean(regex.test(event.target.value) && event.target.value && event.target.value.length >= minLength && event.target.value.length <= maxLength)
}

export const validatePassword = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number, minLength: number) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{12,}$/;
  const password = event.target.value;
  if (!regex.test(password)) return false;
  return password.length >= minLength && password.length <= maxLength;
}

export const validateCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
  return Boolean(event.target.checked === true).valueOf()
}

export const validateDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
  return Boolean(event).valueOf()
}

export const validateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  const regext = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regext.test(String(event.target.value).toLowerCase())
}

export const validateNumberInput = (event: React.ChangeEvent<HTMLInputElement>, length?: number) => {
  return Boolean(event).valueOf() && event.target.value.length === length
}

export const validatePhoneInput = (number: string) => {
  const regex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?(\d{3})[-.\s]?(\d{4})(\d*)$/
  return regex.test(number)
}

export const validatePasscode = (event: string) => {
  return Boolean(event && event.length === 6).valueOf()
}

export const validateVerifCode = (code: string) => {
  const regex = new RegExp(/^[a-zA-Z0-9]+$/)
  return regex.test(code)
}
