import React, { useEffect, useState } from 'react'
import { Button, Notification, PasswordInput } from '..'
import parse from 'html-react-parser'
import i18n from '../../i18n'
import { useTriggeredUpdatePasswordReset } from '../../services'
import { useContext, updateError } from '../../Context'
import { InputI, InputValidateI, errorI, ResetPasswordFormI } from '../ComponentInterface'

const ResetPasswordForm = ({ successFunc, resetToken }: ResetPasswordFormI) => {
  const { useUpdatePasswordReset: updatePasswordreset, isLoading } = useTriggeredUpdatePasswordReset()
  const { state, dispatch } = useContext()
  const [inputs, setInputs] = useState<InputI>({
    serial: '',
    dln: '',
    expiryDate: '',
    dob: '',
  })
  const [validInputs, setValidateInputs] = useState<InputValidateI>({
    isValidBtn: false,
  })
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      errorNotification: errorObj.error === 'error-500-2',
    })
    // eslint-disable-next-line
  }, [errorObj.error])

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidBtn: Boolean(validInputs.isValidNewPassword) && Boolean(validInputs.isValidConfirmPassword) && Boolean(inputs.newPassword === inputs.confirmPassword),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidNewPassword, validInputs.isValidConfirmPassword, inputs.confirmPassword, inputs.newPassword])

  const submitClick = async () => {
    const { response, error } = await updatePasswordreset({
      data: { token: resetToken, password: inputs.newPassword },
    })
    if (response.isSuccess) {
      successFunc && successFunc()
    } else if (error.isUnauthorizedError || error.isForbiddenError) {
      dispatch(updateError(true))
    } else {
      setErrorObj({ ...errorObj, error: 'error-500-2' })
    }
  }

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        {errorObj.errorNotification && (
          <Notification
            text={parse(
              i18n.t(errorObj.error, {
                gpei: state.config?.links?.gpei,
              }),
            )}
            className="error"
            dataTestId="banner-error"
          />
        )}
        <PasswordInput
          minLength={12}
          onChange={(e: any) => setInputs({ ...inputs, newPassword: e.target.value })}
          value={inputs.newPassword}
          label={i18n.t('new-password')}
          onValidate={(e) => {
            setValidateInputs({
              ...validInputs,
              isValidNewPassword: e,
            })
          }}
          description={parse(i18n.t('password-creation-description'))}
          required
          dataTestId="form-new-pw"
        />
        <div style={{ marginTop: '24px' }}>
          <PasswordInput
            minLength={12}
            onChange={(e: any) =>
              setInputs({
                ...inputs,
                confirmPassword: e.target.value,
              })
            }
            value={inputs.confirmPassword}
            label={i18n.t('confirm-password')}
            onValidate={(e) => {
              setValidateInputs({
                ...validInputs,
                isValidConfirmPassword: e,
              })
            }}
            required
            dataTestId="form-confirm-new-pw"
          />
        </div>
        <div style={{ marginTop: '32px' }}>
          <Button onClick={submitClick} text={i18n.t('reset')} disabled={!validInputs.isValidBtn} isLoading={isLoading} dataTestId="Button-primary" />
        </div>
      </form>
    </>
  )
}

export default ResetPasswordForm
