import React from "react";

const Stepper = ({
	label,
	numberofStep,
	currentStep = 0,
	className,
}: {
	label?: string;
	numberofStep: any;
	currentStep?: number;
	className?: string;
}) => {
	const createArrayOfNumberOfSteps = () => {
		if (numberofStep < 1) {
			return [];
		}
		return Array.from({ length: numberofStep }, (_, i) => i + 1);
	};

	const handleClassName = (step: number, isLine?: boolean) => {
		if (step < currentStep) {
			return "complete";
		} else if (step === currentStep && !isLine) {
			return "current";
		} else {
			return "incomplete";
		}
	};

	return (
		<div className={`stepper ${className || ""}`}>
			<div className="label">
				<span>{label}</span>
			</div>

			<div className="steps-container">
				{createArrayOfNumberOfSteps().map((step: number) => {
					return (
						<div
							className={`step-container ${
								step === createArrayOfNumberOfSteps().at(-1)
									? "last-step"
									: ""
							}`}
							key={step}
						>
							<div
								className={`step ${handleClassName(step)}`}
								data-testid={`step-${step}--${handleClassName(
									step
								)}`}
							>
								{step}
							</div>

							{step !== createArrayOfNumberOfSteps().at(-1) && (
								<div
									className={`line ${handleClassName(
										step,
										true
									)}`}
								/>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Stepper;
