import React, { useEffect, useState } from "react";
import { Layout, PasscodeForm, Text, Title, Notification } from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import {
	AuthenticatorI,
	PageI,
	errorI,
} from "../../components/ComponentInterface";
import { useTriggeredAuthenticatorMethod } from "../../services";
import { ReactComponent as QuestionIcon } from '../../asset/icon_question.svg'
import { useContext } from "../../Context";

const SignInOTPPage = ({
	successFunc,
	goBackLink,
	otherRoutes: { apiType = "post" } = {},
}: PageI) => {
	const { useAuthenticatorMethod: authenticatorMethod } =
		useTriggeredAuthenticatorMethod();
	const [authMethod, setAuthMethod] = useState<AuthenticatorI>({});
	const [errorObj, setErrorObj] = useState<errorI>({ error: "" });
	const error500 = () => setErrorObj({ ...errorObj, error: "error-500-1" });
	const {
		state: {
			config: {
				links: { gpei },
			},
		},
	} = useContext();

	const getDescriptionKey = (method: string | undefined) => {
		switch (method) {
			case "TOTP":
				return { description: "one-time-password-totp-description", title: "one-time-password-title" };
			case "SMS":
				return { description: "one-time-password-sms-description", title: "one-time-password-title" };
			default:
				return { description: "one-time-password-description", title: "one-time-password-title-passcode" };
		}
	};

	// Authenticator Method API Call
	useEffect(() => {
		const getAuthMethod = async () => {
			try {
				if (!errorObj.error) {
					const { response } = await authenticatorMethod();
					if (response.isSuccess) {
						const data = response.data;
						setAuthMethod(data);
					} else {
						error500();
					}
				}
			} catch (e) {
				console.log("e", e);
				error500();
			}
		};

		getAuthMethod();
		// eslint-disable-next-line
	}, []);
	return (
		<Layout
			header
			footer
			title={(i18n.t(getDescriptionKey(authMethod.method).title))} 
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
			className="one-time-password-form"
		>
			{errorObj.error && <Notification text={parse(i18n.t(errorObj.error))} className="error" dataTestId="banner-error" />}
			<Title title={(i18n.t(getDescriptionKey(authMethod.method).title))} />
			<Text className="lead-text">{parse(i18n.t(getDescriptionKey(authMethod.method).description))}</Text>
			<PasscodeForm
				successFunc={successFunc}
				apiType={apiType}
				twoFaMethod={authMethod.method}
			/>
			<div className="icon-info-item"><QuestionIcon/>{parse(i18n.t("having-trouble-contact-support", { gpei }))}</div>
		</Layout>
	);
};

export default SignInOTPPage;
