import React from 'react'
import { Button, Layout, Text, Title } from '../../components'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { ReactComponent as CheckIcon } from '../../asset/icon_check.svg'

import { PageI } from '../../components/ComponentInterface'

const AccountInPersonReadyPage = ({ goBackLink, otherRoutes: { skipClick, nextClick } }: PageI) => {
  return (
    <Layout
      header
      footer
      title={i18n.t('in-person-account-ready-title')}
      className={'account-ready progress-bar no-back'}
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={6}
    >
      <div className="account-ready-title">
        <CheckIcon />
        <Title title={i18n.t('in-person-account-ready-title')} />
      </div>
      <Text className="lead-text">{parse(i18n.t('in-person-account-ready-description-1'))}</Text>
      <Text className="withLink account-ready-last">
        {parse(
          i18n.t('in-person-account-ready-description-2', {
            link: `${window.GLOBAL_PATH}`,
          }),
        )}
      </Text>
      <div className="button-container-3">
        <Button secondary text={i18n.t('in-person-account-primary-button')} onClick={skipClick} dataTestId="Button-primary" />
      </div>
    </Layout>
  )
}

export default AccountInPersonReadyPage
