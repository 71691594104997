import React from 'react'

import AppRoutes from './routes'
import Providers from './providers'
import useConfigSetup from './common/hooks/useConfigSetup'
import { useTranslation } from 'react-i18next';

import './styles/App.css'
import './styles/App.scss'

function App() {
  const { ready } = useConfigSetup()
  useTranslation();

  if (!ready) {
    return null
  }

  return (
    <>
      <Providers>
        <AppRoutes />
      </Providers>
    </>
  )
}

export default App
