import React from 'react'

type Props = {
  children: any
  condition: boolean
}

const If = ({ children, condition }: Props) => <>{condition && children}</>

export default If
