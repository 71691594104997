import React from "react";
import { Footer, Header, LinkButton, Stepper } from ".";
import { Helmet } from "react-helmet";
import { LayoutI } from "./ComponentInterface";
import i18n from "../i18n";
import { useContext } from "../Context";

const Layout = ({
	header,
	footer,
	logout,
	backText,
	backBtnCallback,
	backclassName,
	children,
	className,
	title = "title",
	moreLinks,
	stepper,
	stepperLabel,
	stepperNumberofSteps,
	stepperCurrentStep,
}: LayoutI) => {
	const item = moreLinks && { moreLinks };
	const {
		state: {
			config: { favicon },
		},
	} = useContext();
	return (
		<div
			className={`page ${className ? className + "-layout" : ""} ${
				footer ? "footer" : ""
			} ${header ? "header" : ""}  ${backText ? "back-layout" : ""}`}
		>
			<Helmet>
				<meta charSet={i18n.t("charset")} />
				<link href={i18n.t("favicon")} />
				<title>{i18n.t(title).toString()}</title>
				<noscript>{i18n.t("noscript").toString()}</noscript>
				<link rel="shortcut icon" href={favicon} />
			</Helmet>
			{header && <Header logout={logout} />}
			<div className={`${className ? className : ""} layout-content`}>
				{/* Stepper */}

				{stepper && (
					<Stepper
						label={stepperLabel}
						numberofStep={stepperNumberofSteps}
						currentStep={stepperCurrentStep}
					/>
				)}

				{backText && (
					<LinkButton
						onClick={backBtnCallback}
						text={backText}
						className={`${backclassName ? backclassName : ""} back-btn`}
						dataTestId="Button-link-back"
					/>
				)}

				{children && children}
			</div>
			{footer && <Footer {...item} />}
		</div>
	);
};

export default Layout;
