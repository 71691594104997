import { IWalletClientConfig, IWalletClientStorage } from '.'
import { ConsentRequest } from './services/ConsentRequest'
import { DataSource } from './services/Datasource'
import { Idp } from './services/Idp'
import { configureServices } from './services/ServiceRegistry'
import { WalletStorageMap, StorageCrud } from './types/wallet'

export const createWalletStorage = <T extends WalletStorageMap>(storage: StorageCrud<T>): StorageCrud<T> => {
  if (!storage || typeof storage.get !== 'function' || typeof storage.set !== 'function' || typeof storage.delete !== 'function') {
    throw new Error('Invalid storage object')
  }

  return {
    get: (key, isObject) => storage.get(key, isObject),
    set: (key, value, isObject) => storage.set(key, value, isObject),
    delete: (key) => storage.delete(key),
  }
}

export const createWalletClient = (config: IWalletClientConfig, storage: IWalletClientStorage) => {
  configureServices(config, storage)

  const idp = Idp()
  const datasource = DataSource()
  const consentRequest = ConsentRequest()

  return {
    Wallet: {
      getAccessToken: () => storage.get('session'),
    },
    Idp: {
      ...idp,
    },
    DataSource: {
      ...datasource,
    },
    Consent: {
      ...consentRequest,
    },
  }
}
