import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type ScrollToHashOptions = {
  beforeScroll?: (element: HTMLElement) => void
  afterScroll?: (element: HTMLElement) => void
  delay?: number
  scrollOptions?: boolean | ScrollIntoViewOptions
}

const useScrollToHash = ({ beforeScroll, afterScroll, delay = 0, scrollOptions = true }: ScrollToHashOptions = {}) => {
  const { hash } = useLocation()
  useEffect(() => {
    if (hash) {
      const elementId = hash.replace('#', '')
      const hashElement = document.getElementById(elementId)
      if (hashElement) {
        if (beforeScroll) beforeScroll(hashElement)
        setTimeout(() => {
          requestAnimationFrame(() => {
            hashElement.scrollIntoView(scrollOptions)

            if (afterScroll) afterScroll(hashElement)
          })
        }, delay)
      }
    }
  }, [hash, beforeScroll, afterScroll, delay, scrollOptions])
}

export default useScrollToHash
