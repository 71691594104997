import parse from 'html-react-parser'
import React from 'react'
import { Layout, Text, Title } from '../../components'
import { PageI } from '../../components/ComponentInterface'
import SmsAuthSetupStep2 from '../../components/smsAuthSetup/step2'
import i18n from '../../i18n'

const SMSOTPPage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout
      header
      footer
      title={i18n.t('setup-sms-auth')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="choose-2FA sms-otp progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={5}
    >
      <Title title={i18n.t('setup-sms-title')} />
      <Text className="lead-text">{parse(i18n.t('setup-sms-description'))}</Text>
      <SmsAuthSetupStep2 onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default SMSOTPPage
