import { DataSourceResource, DataSourceAccount } from '../types/datasource'
import { CreatePermission } from '../types/permission'
import { ScopeRequested, RequestedResource } from '../types/transaction'

interface DataSourceResourceWithDSA extends DataSourceResource {
  dsa_id: string
}

/**
 * Transforms a list of `DataSourceAccount` objects into a map where each key is a resource definition ID (`res_def_id`)
 * and each value is an array of `ExtendedDataSourceResource` objects. This transformation includes extending each resource
 * with the `ds_account_id` of its parent account, facilitating the association of data source accounts with their respective
 * resources and the required `dsa_id` for permission payload construction.
 *
 * Map -> [res_def_id:{...resource, dsa_id}]
 */
function transformDsAccounts(dsAccounts: DataSourceAccount[]) {
  const resourceMap = new Map<string, DataSourceResourceWithDSA[]>()

  dsAccounts.forEach((account) => {
    account.data_source?.resources?.forEach((resource) => {
      if (!resourceMap.has(resource.resource.res_def_id)) {
        resourceMap.set(resource.resource.res_def_id, [])
      }

      resourceMap.get(resource.resource.res_def_id)?.push({
        ...resource,
        dsa_id: account.ds_account_id,
      })
      // eslint-disable-next-line
      resourceMap
    })
  })

  return resourceMap
}

function validateApprovedScopes(approvedScopes: string[], scopesRequested: ScopeRequested[]) {
  return scopesRequested.map((scope) => scope.scope).filter((scope) => approvedScopes.includes(scope))
}

function createPermissionPayload(resourceMap: Map<string, DataSourceResourceWithDSA[]>, requestedResource: RequestedResource) {
  const {
    resource_definition: { res_def_id },
    scopes_requested,
  } = requestedResource
  const resources = resourceMap.get(res_def_id) || []

  return resources.flatMap(({ dsa_id, rs_res_id, approved_scopes }) => {
    const scopes_granted = validateApprovedScopes(approved_scopes, scopes_requested)
    return scopes_granted.length > 0 ? [{ dsa_id, rs_res_id, scopes_granted }] : []
  })
}

export function matchAndValidatePermissions(requestedResources: RequestedResource[], dsAccounts: DataSourceAccount[]): CreatePermission[] {
  const resourceMap = transformDsAccounts(dsAccounts)
  return requestedResources.flatMap((resource) => createPermissionPayload(resourceMap, resource))
}
