import React from 'react';
import { validateCheckbox } from './validate';
import { CheckboxI } from './ComponentInterface';

const Checkbox = ({ value, onChange, label, id, onValidate }: CheckboxI) => {
    const onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(e);
        if (onValidate) onValidate(validateCheckbox(e));
    }
    return (
        <div className="checkbox-container">
            <label className="container" htmlFor={id}>
                <p>{label}</p>
                <input type="checkbox" id={id} name={id} onChange={onCheckboxClick} checked={value} formNoValidate />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

export default Checkbox;
