import React from 'react'
import { Layout, Title } from '../../components'
import AppAuthSetupStep3 from '../../components/appAuthSetup/step3'
import { PageI } from '../../components/ComponentInterface'
import i18n from '../../i18n'

const AddAuthenticatorCodePage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout
      header
      footer
      title={i18n.t('setup-authenticator-app-otp-title')}
      className="choose-2FA authenticator-app-flow step-3 progress-bar"
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={5}
    >
      <Title title={i18n.t('setup-authenticator-app-otp-title')} />
      <AppAuthSetupStep3 onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default AddAuthenticatorCodePage
