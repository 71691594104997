import React, { useState } from "react";
import { Button, Layout, Notification, Text, Title } from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { useTriggeredPasswordReset } from "../../services";
import { useContext, updateError } from "../../Context";
import { PageI } from "../../components/ComponentInterface";

const ForgotPasswordCheckEmailPage = ({ goBackLink }: PageI) => {
	const {
		state: {
			userInfo: { forgot_email },
		},
		dispatch,
	} = useContext();
	const { usePasswordReset: updatePasswordReset, isLoading } =
		useTriggeredPasswordReset();
	const [message, setMessage] = useState("");

	const onSuccess = async () => {
		const { response, error } = await updatePasswordReset({
			data: { email: forgot_email },
		});
		if (response.isSuccess) {
			setMessage("forgot-password-email-success");
		} else if (error.isUnauthorizedError || error.isForbiddenError) {
			dispatch(updateError(true));
		} else {
			setMessage("error-500-1");
		}
	};
	return (
		<Layout
			header
			footer
			title={i18n.t("forgot-password-email-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
		>
			<Title title={i18n.t("forgot-password-email-title")} />
			<Text className="forgot-password-description">
				{parse(i18n.t("forgot-password-email-description"))}
			</Text>
			<form
				onSubmit={(e) => e.preventDefault()}
				autoComplete="off"
				className="forgot-password-form"
			>
				{message === "error-500-1" && (
					<Notification
						text={parse(i18n.t(message))}
						className="error"
						dataTestId="banner-error"
					/>
				)}
				<Button
					onClick={onSuccess}
					text={i18n.t("resend")}
					isLoading={isLoading}
					dataTestId="Button-primary"
				/>
				{message === "forgot-password-email-success" && (
					<Text className="code-sent" dataTestId="body">
						{i18n.t("forgot-password-email-success")}
					</Text>
				)}
			</form>
		</Layout>
	);
};

export default ForgotPasswordCheckEmailPage;
