import React, { useEffect } from 'react'
import queryString from 'query-string'

import { useHistory } from 'react-router-dom'
import { ConsentRequestCallback } from '../../../../wallet'
import { useWallet } from '../../../../context/wallet'
import { useWalletFlowHelper } from '../../../../context/wallet/WalletFlowHelper'
import { Spinner } from '../../../../components'
import ROUTES from '../../../../routes/common/constants'
import { getServices } from '../../../../wallet/services/ServiceRegistry'
import { updateToken, useContext } from '../../../../Context'

const WalletConsentCallback = () => {
  const history = useHistory()
  const { storage } = getServices()
  const { dispatch } = useContext()

  const {
    ConsentRequestTokenHistory: { add },
  } = useWalletFlowHelper()

  const {
    Consent: { handleCallback },
  } = useWallet()

  const callHandler = async () => {
    const { token, client, autoConsent }: ConsentRequestCallback = queryString.parse(window.location.search) as any
    
    if (window.location.search.indexOf('auto_consent') !== -1) {
      storage.set('auto_consent', autoConsent)
      storage.delete('session');
      dispatch(updateToken(''))
    }

    const transaction = await handleCallback(token, client)

    if (transaction) {
      add(transaction)

      history.push(ROUTES.WalletIdentityVerification)
    } else {
      history.push(ROUTES.WalletRoute)
    }
  }

  useEffect(() => {
    callHandler()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletConsentCallback
