import { useCallback, useEffect, useState } from 'react'

import { useWalletFlowHelper } from '../../../../../context/wallet/WalletFlowHelper'
import { RequestedResource } from '../../../../../wallet/types/transaction'
import { useContext } from '../../../../../Context'

const hashString = async (input: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(input)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const useScopesFromHash = (transaction_id: string) => {
  const [html, setHtml] = useState({ title: '', body: '', terms: '' })
  const [consentKey, setConsentKey] = useState("");

  const {
    ConsentRequestTokenHistory: { get },
  } = useWalletFlowHelper()

  const {
    state: {
      config: { enrolled_clients },
    },
  } = useContext()

  const sortAndFilterResources = useCallback((resources: RequestedResource[]) => {
    return Object.fromEntries(
      resources
        .map((resource): [string, string[]] => [resource.resource_definition.res_def_id, resource.scopes_requested.sort().map((scope) => scope.scope)])
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
    )
  }, [])

  useEffect(() => {
    let isMounted = true

    const handleHashResources = async () => {
      const { client, requested_resources } = get(transaction_id)
      if (!client || !requested_resources) return

      const sortedRequestedResources = requested_resources && sortAndFilterResources(requested_resources)

      const key = await hashString(JSON.stringify(sortedRequestedResources))

      setConsentKey(key);

      const consent_ui = enrolled_clients?.[client.identifier].consent_ui?.[key]?.['en']
      if (!consent_ui) {
        return
      }

      if (isMounted) {
        setHtml(consent_ui)
      }
    }
    handleHashResources()

    return () => {
      isMounted = false
    }
  }, [enrolled_clients, get, transaction_id, sortAndFilterResources])

  return { html, consentKey }
}

export default useScopesFromHash
