import parse from 'html-react-parser'
import React from 'react'
import { Layout, Text, Title } from '../../../components'
import { PageI } from '../../../components/ComponentInterface'
import SmsAuthSetupStep2 from '../../../components/smsAuthSetup/step2'
import i18n from '../../../i18n'

const Change2FASmsOtpPage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back')} title={i18n.t('change-auth-title')} className={'change-2FA'}>
      <Title title={i18n.t('setup-sms-title')} />
      <Text className="lead-text">{parse(i18n.t('setup-sms-description'))}</Text>
      <SmsAuthSetupStep2 onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default Change2FASmsOtpPage
