import React from 'react'
import { IconListItemI } from './ComponentInterface'

const IconListItem = ({ children, className = '', logo, complete, dataTestId }: IconListItemI) => (
  <div className={`icon-list-item ${className} ${complete ? 'complete' : ''}`} data-testid={dataTestId}>
    <i>{logo}</i>
    {children}
  </div>
)

export default IconListItem
