import React from 'react'
import { Layout, Title } from '../../../components'
import AppAuthSetupStep1 from '../../../components/appAuthSetup/step1'
import { PageI } from '../../../components/ComponentInterface'
import i18n from '../../../i18n'

const Change2FAAppSetupPage = ({ goBackLink, successFunc }: PageI) => {
  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back')} title={i18n.t('change-auth-title')} className={'change-2FA authenticator-app-flow step-1'}>
      <Title title={i18n.t('setup-authenticator-app-title')} />
      <AppAuthSetupStep1 onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default Change2FAAppSetupPage
