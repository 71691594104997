import React from 'react'
import i18n from '../../../../../i18n'

import { Card } from '../../../../../components'

const ProfileCardInfo = () => {
  return (
    <div className="wallet-profile-cards">
      <Card title={i18n.t('wallet-profile-share-title')} description={i18n.t('wallet-profile-share-description')} dataTestId="info-box-what-share" />
      <Card title={i18n.t('wallet-profile-information-shown-title')} description={i18n.t('wallet-profile-information-shown-description')} dataTestId="info-box-where-info" />
    </div>
  )
}

export default ProfileCardInfo
