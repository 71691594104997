import { createWalletClient, createWalletStorage } from '../wallet'
import LocalStorage from './LocalStorage'

const wallet = (configData: any) => {
  const storage = createWalletStorage(LocalStorage)
  const walletInstance = createWalletClient(configData, storage)

  return walletInstance
}

export default wallet
