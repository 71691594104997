import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Modal, Button } from '../../../../../../components'
import i18n from '../../../../../../i18n'

interface Props {
  onClose: () => void
  isOpen: boolean
  updateAction: () => void
}

const UpdateHealthCardModal = ({ onClose, isOpen, updateAction }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    !isOpen && setIsLoading(false)
  }, [isOpen])

  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'update-verified-health-info-modal'}>
        <h4 className="title" data-testid={'key-title'}>
          {parse(i18n.t('update-verified-health-card-title'))}
        </h4>
        <div className="subtitle" data-testid={'Body'}>
          <p>{parse(i18n.t('update-verified-health-card-body-1'))}</p>
          <p>{parse(i18n.t('update-verified-health-card-body-2'))}</p>
          <p>{parse(i18n.t('update-verified-health-card-body-3'))}</p>
        </div>
        <div className="btn-group">
          <Button dataTestId="Button-secondary" secondary className={'btn-health-card-cancel'} onClick={onClose} text={i18n.t('cancel')} />
          <Button
            dataTestId="Buttom-primary"
            onClick={() => {
              setIsLoading(true)
              updateAction()
            }}
            className="btn-health-card"
            text={i18n.t('update-now')}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default UpdateHealthCardModal
