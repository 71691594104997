import { useEffect, useState } from 'react'
import { getMyEnrolledClientPermissionsById, getMyOrganizations, GrantedPermissions, MyOrganization, revokeMyEnrolledClientPermissionsById } from '../../../wallet'
import i18n from '../../../i18n'

const useConsentHistoryDetails = (type: string, id: string) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isRevokeLoading, setIsRevokeLoading] = useState(false)
  const isService = type === i18n.t('wallet-consent-history-type-service')
  const [permissions, setPermissions] = useState<GrantedPermissions[]>([])
  const [org, setOrg] = useState<MyOrganization[]>([])

  useEffect(() => {
    if (isService && permissions.length === 0 && isLoading) {
      getMyEnrolledClientPermissionsById(id, { not_disabled: true }).then(async (dataNotRevoked) => {
        const dataRevoked = await getMyEnrolledClientPermissionsById(id, { not_disabled: false })

        if (!dataNotRevoked.length) {
          setPermissions(dataRevoked)
          setIsLoading(false)
        } else {
          const listOfPermissions = [] as GrantedPermissions[]

          dataRevoked?.forEach((permissionRevoked) => {
            const permissionIsValid = dataNotRevoked.filter((permissionNotRevoked) => permissionNotRevoked.permission_id === permissionRevoked.permission_id)
            if (permissionIsValid.length) listOfPermissions.push(permissionIsValid[0])
            else listOfPermissions.push(permissionRevoked)
          })

          setPermissions(listOfPermissions)
          setIsLoading(false)
        }
      })
    }

    if (!isService && org.length === 0 && isLoading) {
      getMyOrganizations().then((data) => {
        setIsLoading(false)
        setOrg(data)
      })
    }
  }, [id, isService, org, permissions, isLoading])

  const handleRevoke = (callback: any) => {
    setIsRevokeLoading(true)
    revokeMyEnrolledClientPermissionsById(id).then((data) => {
      const revokeAll = permissions.map((permission) => ({ ...permission, disabled: 'disabled' }))
      setPermissions(revokeAll)
      setIsRevokeLoading(false)
      callback && callback()
    })
  }

  return { isLoading, isService, permissions, org, handleRevoke, isRevokeLoading }
}

export default useConsentHistoryDetails
