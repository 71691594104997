import React, { useMemo } from 'react'

import { IWalletClientStorage } from '../../wallet'

import { WalletFlowHelperContext } from '../../context/wallet/WalletFlowHelper'
import ConsentRequestTokenHistory from '../../services/ConsentRequestManager'

export interface WallerProviderProps {
  children: React.ReactNode
  storage: IWalletClientStorage
}

const WalletFlowHelperProvider = ({ children, storage }: WallerProviderProps) => {
  const consentRequestTokenHistory = ConsentRequestTokenHistory(storage)
  const providerValue = useMemo(() => ({ ConsentRequestTokenHistory: consentRequestTokenHistory }), [consentRequestTokenHistory])

  return <WalletFlowHelperContext.Provider value={providerValue}>{children}</WalletFlowHelperContext.Provider>
}

export default WalletFlowHelperProvider
