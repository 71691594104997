import { useEffect, useState } from "react";
import { InputI, InputValidateI, errorI } from "../../ComponentInterface";

const useErrorHandling = () => {
	const [errorObj, setErrorObj] = useState<errorI>({ error: "" });

	useEffect(() => {
		setErrorObj((prevErrorObj) => ({
			...prevErrorObj,
			errorConfirmPassword:
				prevErrorObj.error ===
					"error-account-create-password-requirement" ||
				prevErrorObj.error === "error-account-create-password-match",
			errorEmail:
				prevErrorObj.error === "error-account-create-email-exist",
			errorNotification: prevErrorObj.error === "error-500-1" || prevErrorObj.error === "error-account-create-email-exist",
		}));
	}, [errorObj.error]);

	const handleApiError = (apiError: errorI) => {
		setErrorObj({ ...errorObj, error: apiError.error });
	};

	const error500 = () => setErrorObj({ ...errorObj, error: "error-500-1" });

	const handleLocalErrors = (inputs: InputI, validInputs: InputValidateI) => {
		const hasLocalError =
			inputs.confirmPassword !== inputs.createPassword ||
			!validInputs.isValidPassword;
		if (hasLocalError) {
			setErrorObj({
				...errorObj,
				error: !validInputs.isValidPassword
					? "error-account-create-password-requirement"
					: inputs.confirmPassword !== inputs.createPassword
					? "error-account-create-password-match"
					: "",
			});
			return true;
		}
		return false;
	};

	return { errorObj, error500, handleLocalErrors, handleApiError };
};

export default useErrorHandling;
