import { getServices } from '../services/ServiceRegistry'
import { getOpenIdConfiguration } from './WellKnown'

interface IGenerateOAuthUrl {
  scopePrefix: 'idp' | 'ds'
  identifier: string
  responseType: 'code' | 'none'
  redirect_uri: string
}

export async function generateOAuthUrl({ scopePrefix, identifier, responseType, redirect_uri }: IGenerateOAuthUrl) {
  const { config } = getServices()
  
  const scope = `${scopePrefix} ${identifier}`
  const state = generateRandomString(16)
  const codeVerifier = generateRandomString(64)
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  const { authorization_endpoint } = await getOpenIdConfiguration()

  let oauthUrl =
    `${authorization_endpoint}?` +
    `client_id=${encodeURIComponent(config.client_id)}&` +
    `response_type=${encodeURIComponent(responseType)}&` +
    `redirect_uri=${encodeURIComponent(redirect_uri)}&` +
    `state=${encodeURIComponent(state)}&` +
    `scope=${encodeURIComponent(scope)}&` +
    `code_challenge_method=S256&` +
    `code_challenge=${encodeURIComponent(codeChallenge)}`

  return { oauthUrl, state, codeVerifier }
}

export async function generateCodeChallenge(codeVerifier: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const hash = await crypto.subtle.digest('SHA-256', data)
  return base64UrlEncode(hash)
}

export function base64UrlEncode(arrayBuffer: ArrayBuffer) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer) as any))
    .replace(/\+/g, '-') // Replace + with -
    .replace(/\//g, '_') // Replace / with _
    .replace(/=+$/, '') // Remove padding
}

export function generateRandomString(length: number) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}
