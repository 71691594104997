import React from "react";
import i18n from "../../i18n";
import IconButton from "../buttons/IconButton";
import { ReactComponent as Info } from "../../asset/icon-question.svg";
import { ReactComponent as Error } from "../../asset/icon-input-error.svg";
import { validateNameInput } from "../validate";
import { NameInputI } from "../ComponentInterface";

const NameInput = ({
	value,
	onChange,
	maxLength,
	minLength,
	optional,
	label,
	showCtaIcon,
	infoClick,
	type,
	onValidate,
	readOnly = false,
	placeholder,
	required,
	isError,
	labelDescription,
	editClick,
	ctaBtnText,
	name,
	isFocused,
	hideCta,
	dataTestId,
}: NameInputI) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(e, e.target.value.slice(0, maxLength));
			// // remove error className when input changes
			// if (e?.target?.parentElement?.className?.includes('error')) {
			//     e.target.parentElement.className = e?.target?.parentElement?.className.replace('error', '')
			// }
		}

		if (onValidate) {
			onValidate(
				validateNameInput(e || "", maxLength || 150, minLength || 0)
			);
		}
	};

	return (
		<div className={`textbox-form ${readOnly ? "read-only" : ""}`}>
			<label htmlFor={label}>
				<strong>{label}</strong> {labelDescription}{" "}
				{optional && <span>{`${i18n.t("optional")}`}</span>}{" "}
				{!readOnly && required && <strong>*</strong>}
			</label>
			<div
				className={`textbox-form-input  ${
					infoClick ? "textbox-form-input-info" : ""
				} ${isError ? "error" : ""} ${
					isFocused && !readOnly && !isError ? "active-border" : ""
				}`}
			>
				<input
					onChange={onInputChange}
					type="text"
					name={name}
					maxLength={maxLength}
					minLength={minLength}
					readOnly={readOnly}
					placeholder={placeholder}
					value={value}
					id={label}
					formNoValidate
					data-testid={dataTestId}
				/>
				{infoClick && !readOnly && !hideCta && (
					<IconButton
						onClick={infoClick}
						icon={<Info />}
						type={type}
					/>
				)}
				{isError && !infoClick && <div className="error-icon-container"><Error className="error-icon" /></div>}
				{showCtaIcon && (
					<IconButton
						onClick={infoClick}
						icon={<Info />}
						type={type}
					/>
				)}
				{ctaBtnText && (
					<button className="info-btn text-btn" onClick={editClick}>
						{ctaBtnText}
					</button>
				)}
			</div>
		</div>
	);
};

export default NameInput;
