import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

import useConsent from '../../../hooks/useConsent'
import { getServices } from '../../../../../wallet/services/ServiceRegistry'
import { Button } from '../../../../../components'

import useIdvVerification from '../../../hooks/useIdvVerification'
import { EIDVBackBtn } from '../../../../../wallet/types/wallet'

const Unverified = () => {
  const [scopeName, setScopeName] = useState({ client: '' })

  const { handleStartIdvVerification, handleInPerson } = useIdvVerification()

  const { storage } = getServices()

  const transactionID = storage.get('cr_token')
  const { generateClientName, handleDisagreeButtonClick } = useConsent(transactionID!)

  useEffect(() => {
    if (!scopeName?.client) {
      setScopeName({ client: generateClientName() })
    }
  }, [scopeName, generateClientName])

  const handleIdvRedirection = () => handleStartIdvVerification(false, EIDVBackBtn.CONSENT_IDENTITY)

  return (
    <div className="wallet-identity-unverified">
      <div className="wallet-identity-unverified-subtitle" data-testid={'unverified-subtitle'}>
        {parse(i18n.t('wallet-identity-verification-unverified-desc-1', { service: scopeName.client }))}
      </div>

      <div className="wallet-identity-desc" data-testid={'unverified-desc'}>
        {parse(i18n.t('wallet-identity-verification-unverified-desc-2'))}
      </div>

      <div className="wallet-list-container">
        <ol>
          <li> {parse(i18n.t('wallet-identity-verification-unverified-desc-2-item-1'))}</li>
          <li> {parse(i18n.t('wallet-identity-verification-unverified-desc-2-item-2'))}</li>
          <li> {parse(i18n.t('wallet-identity-verification-unverified-desc-2-item-3'))}</li>
        </ol>
      </div>

      <div className="wallet-identity-desc-1" data-testid={'unverified-desc'}>
        {parse(i18n.t('wallet-identity-verification-unverified-desc-3'))}
      </div>
      <div className="wallet-identity-desc-1" data-testid={'unverified-desc'}>
        {parse(i18n.t('wallet-identity-verification-unverified-desc-4'))}
      </div>

      <Button
        className={'wallet-identity-unverified-btn1'}
        isLoading={false}
        onClick={handleIdvRedirection}
        text={i18n.t('wallet-identity-verification-unverified-btn-1')}
        dataTestId={'wallet-identity-unverified-btn1'}
      />

      <div className="wallet-identity-desc" data-testid={'unverified-desc-2'}>
        {parse(i18n.t('wallet-identity-verification-unverified-desc-5'))}
      </div>

      <div className="wallet-list-container">
        <ol>
          <li> {parse(i18n.t('wallet-identity-verification-unverified-desc-5-item-1'))}</li>
          <li> {parse(i18n.t('wallet-identity-verification-unverified-desc-5-item-2'))}</li>
        </ol>
      </div>

      <Button
        className={'wallet-identity-unverified-btn2'}
        isLoading={false}
        onClick={handleInPerson}
        text={i18n.t('wallet-identity-verification-unverified-btn-2')}
        dataTestId={'Button-primary'}
      />

      <div onClick={handleDisagreeButtonClick} className="wallet-identity-unverified-link" data-testid={'wallet-identity-unverified-link'}>
        {parse(i18n.t('wallet-identity-verification-unverified-skip'))}
      </div>
    </div>
  )
}

export default Unverified
