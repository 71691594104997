import { MyOrganization } from '../../types/organization'
import { makeAuthenticatedApiCall } from '../../utils/ApiHelper'

const URL_ORGANIZATIONS = '/me/organizations'

export const getMyOrganizations = () => 
    makeAuthenticatedApiCall<MyOrganization[]>('GET', URL_ORGANIZATIONS)

export const getMyOrganizationsById = (id: string) => 
    makeAuthenticatedApiCall<MyOrganization & { clients: [] }>('GET', `${URL_ORGANIZATIONS}/${id}`)
