import { useState } from 'react'
import { errorI } from '../../../../components/ComponentInterface'
import { useTriggeredAuthenticatorProfile } from '../../../../services'
import { updateError, useContext } from '../../../../Context'

type Method = 'SMS' | 'EMAIL' | 'TOTP'

type Change2FAOptions = {
  method: Method
}

type Payload = {
  method: Method
  phone_number?: string
}

type SubmitArgs = {
  onSuccess: (response?: any) => Promise<void> | void
  phone_number?: string
}

const useChange2FA = ({ method }: Change2FAOptions) => {
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })
  const { useAuthenticatorProfile: profileAuthenticator, isLoading } = useTriggeredAuthenticatorProfile()
  const { dispatch } = useContext()
  const submit = ({ onSuccess, phone_number }: SubmitArgs) => {
    const executeSubmit = async () => {
      try {
        const payload: Payload = { method }
        if (method === 'SMS') {
          payload.phone_number = phone_number
        }
        const { response, error } = await profileAuthenticator({
          data: payload,
        })
        if (response?.isSuccess) {
          await onSuccess(response)
        } else if (error?.isUnauthorizedError) {
          dispatch(updateError(true))
        } else {
          setErrorObj((prev) => ({ ...prev, error: 'error-500-1' }))
        }
      } catch (e) {
        console.error('error: ', e)
        setErrorObj((prev) => ({ ...prev, error: 'error-500-1' }))
      }
    }
    executeSubmit()
  }

  return { isLoading, submit, errorObj }
}

export default useChange2FA
