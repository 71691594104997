import React from 'react'
import { Layout, PasscodeForm, Text, Title } from '../../../../components'

import parse from 'html-react-parser'
import { PageI } from '../../../../components/ComponentInterface'
import i18n from '../../../../i18n'

const WalletChangeEmailOTPPage = ({ successFunc, goBackLink, otherRoutes: { apiType = 'createAccount' } = {} }: PageI) => {
  return (
    <Layout header footer title={i18n.t('one-time-password-title')} backBtnCallback={goBackLink} backText={i18n.t('back')} className="one-time-password-form progress-bar">
      <Title title={i18n.t('one-time-password-title')} />
      <Text className="lead-text">{parse(i18n.t('one-time-password-description-2'))}</Text>
      <div className="code-form">
        <PasscodeForm successFunc={successFunc} apiType={apiType} twoFaMethod="EMAIL" />
      </div>
    </Layout>
  )
}

export default WalletChangeEmailOTPPage
