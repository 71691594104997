import React, { createContext, useContext, useState, useEffect } from 'react';
import i18n from '../../i18n';

// Import language files directly
import enTranslations from '../../i18n/en.json';
import frTranslations from '../../i18n/fr.json';

interface LanguageContextProps {
  language: string;
  toggleLanguage: () => void;
}

// Define the available languages
const languageResources: Record<string, Record<string, any>> = {
  en: enTranslations,
  fr: frTranslations,
};

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<string>(
    localStorage.getItem('i18nLanguage') || 'en' // Default to 'en'
  );

  const loadLanguageResources = (lang: string) => {
    if (languageResources[lang]) {
      i18n.addResources(lang, 'translations', languageResources[lang]);
    } else {
      console.error(`Language resources for "${lang}" not found`);
    }
  };

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'fr' : 'en'; // Toggle between languages
    loadLanguageResources(newLanguage);
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    localStorage.setItem('i18nLanguage', newLanguage);
  };

  useEffect(() => {
    // Load the initial language resources
    loadLanguageResources(language);
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
