import React, { useEffect, useRef, useState } from 'react'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'
import { ListItem } from '../../../../../components'
import { PasswordChangeForm } from '../forms'

import { AuthenticatorI, errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { convertUTCtoLocalTimezone } from '../../../../../utils'

import { ReactComponent as Checkmark } from '../../../../../asset/icon-check.svg'

import { Text, Notification } from '../../../../../components'
import { maskEmail } from '../../../../../utils'
import ROUTES from '../../../../../routes/common/constants'
import { updateAuthMethodUpdated, useContext } from '../../../../../Context'

interface IAccountProps {
  setProfileData: (data: ProfileDataI) => void
  profileData: ProfileDataI
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  isVerified: boolean | any
  authMethod: AuthenticatorI
  emailUpdated?: boolean
}

const AccountInformation = ({ setProfileData, profileData, errorObj, setErrorObj, validInputs, setValidateInputs, authMethod, emailUpdated }: IAccountProps) => {
  TimeAgo.addLocale(en)
  TimeAgo.addLocale(fr)

  const timeAgo = new TimeAgo(['en-US', 'fr'])
  const [activateUpdatePasswordForm, setActivateUpdatePasswordForm] = useState(false)
  const {
    state: {
      userInfo: { authMethodUpdated = false },
    },
    dispatch,
  } = useContext()

  const [passwordUpdate, setPasswordUpdated] = useState(false)
  const [infoSaved, setInfoSaved] = useState(authMethodUpdated)
  const notificationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (infoSaved) {
      notificationRef.current?.scrollIntoView({ block: 'start' })
      setTimeout(() => {
        setInfoSaved(false)
        dispatch(updateAuthMethodUpdated(false))
      }, 5000)
    }
  }, [infoSaved, dispatch])

  useEffect(() => {
    if (emailUpdated) setInfoSaved(true)
  }, [emailUpdated])

  const toggleUpdatePasswordForm = () => {
    setActivateUpdatePasswordForm(!activateUpdatePasswordForm)
  }

  const passUpdatedTextFunc = () => {
    if (passwordUpdate) return i18n.t('update-just-now')

    return `${i18n.t('updated')} ${profileData?.passwordDate ? timeAgo.format(convertUTCtoLocalTimezone(profileData?.passwordDate)) : ''}`
  }

  return (
    <>
      <div className="profile-information-session">
        <Text className="subtitle" dataTestId="h3-acct-info">
          {parse(i18n.t('wallet-profile-account-information-title'))}
        </Text>
        {infoSaved && <Notification notificationRef={notificationRef} dataTestId="succes-banner" icon={<Checkmark />} text={i18n.t('information-saved')} />}
        <div className="profile-info-group">
          <ListItem
            title={i18n.t('wallet-profile-account-information-email')}
            value={profileData?.email && maskEmail(profileData.email)}
            dataTestId="row-email"
            action={'Edit'}
            url={ROUTES.WalletChangeEmailRoute}
          />
          {activateUpdatePasswordForm ? (
            <PasswordChangeForm
              profileData={profileData}
              setProfileData={setProfileData}
              errorObj={errorObj}
              setErrorObj={setErrorObj}
              validInputs={validInputs}
              setValidateInputs={setValidateInputs}
              toggleUpdatePasswordForm={toggleUpdatePasswordForm}
              setInfoSaved={setInfoSaved}
              setPasswordUpdated={setPasswordUpdated}
              dataTestId="edit-password"
            />
          ) : (
            <ListItem
              title={i18n.t('wallet-profile-account-information-password')}
              value={passUpdatedTextFunc()}
              dataTestId="row-password"
              action={'Edit'}
              onClick={toggleUpdatePasswordForm}
            />
          )}
          <ListItem
            title={i18n.t('wallet-profile-account-information-2fa')}
            value={authMethod?.description || i18n.t('not-provided')}
            dataTestId="row-2FA"
            action={'Edit'}
            url={ROUTES.Change2FARoute}
          />
        </div>
      </div>
    </>
  )
}

export default AccountInformation
