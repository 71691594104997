import parse from 'html-react-parser'
import React, { FC } from 'react'
import { ReactComponent as PhoneIcon } from '../../asset/icon-phone-password.svg'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { AuthenticatorCodeForm, IconListItem, LinkButton, Notification, Text } from '../../components'
import i18n from '../../i18n'
import ROUTES from '../../routes/common/constants'

type AppAuthSetupStep3Props = {
  onSuccess?: () => void
  onBack?: () => void
}

const AppAuthSetupStep3: FC<AppAuthSetupStep3Props> = ({ onSuccess, onBack }) => {
  return (
    <>
      <Text className="subtitle" dataTestId="body-description">
        {parse(i18n.t('setup-authenticator-app-otp-subtitle'))}
      </Text>
      <IconListItem logo={<PhoneIcon />} dataTestId="step4">
        <Text>{parse(i18n.t('setup-authenticator-app-otp-page-step-4'))}</Text>
      </IconListItem>
      <div className="links-container">
        <IconListItem logo={<ShowMeIcon />} dataTestId="Show-how">
          <LinkButton text={i18n.t('setup-authenticator-app-show-me-link')} url={ROUTES.AccountSetupHelpRoute} target="_blank" />
        </IconListItem>
      </div>
      <AuthenticatorCodeForm successFunc={onSuccess} goBackLink={onBack} />

      <Notification text={parse(i18n.t('setup-authenticator-app-otp-go-info-bar'))} className="notice-box" dataTestId="banner-warning" />
    </>
  )
}

export default AppAuthSetupStep3
