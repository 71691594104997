import parse from 'html-react-parser'
import React from 'react'
import { Layout, Text, Title } from '../../../components'
import { PageI } from '../../../components/ComponentInterface'
import SmsAuthSetupStep1 from '../../../components/smsAuthSetup/step1'
import i18n from '../../../i18n'
import useChange2FA from './hooks/useChange2FA'

const Change2FASmsPage = ({ goBackLink, successFunc = () => {} }: PageI) => {
  const { submit, isLoading, errorObj } = useChange2FA({ method: 'SMS' })
  const onSubmit = async (phoneNumber: string) => {
    const rawPhoneNumber = phoneNumber.replace(/[\s\-–—]+/g, '') // remove space/any kind of hyphen from phoneNumber
    submit({ onSuccess: successFunc, phone_number: rawPhoneNumber })
  }
  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back')} title={i18n.t('change-auth-title')} className={'change-2FA'}>
      <Title title={i18n.t('setup-sms-title')} />
      {!!errorObj?.error && <Text className="error">{parse(i18n.t(errorObj.error))}</Text>}
      <SmsAuthSetupStep1 isLoading={isLoading} onSubmit={onSubmit} onBack={goBackLink} />
    </Layout>
  )
}

export default Change2FASmsPage
