import { DataSource } from '../../types/datasource'
import { RegistryDataSourceTypeEnum, RegistryDataSourceResponse } from '../../types/registry'
import { makeApiCall, makeAuthenticatedApiCall } from '../../utils/ApiHelper'
import { buildQueryString } from '../../utils/BuildQueryString'

const URL_DS = '/registry/data-sources'

/**
 * Fetches a collection of data sources of a specified type. Allows for the inclusion of
 * additional resource details based on query parameters.
 *
 * @param type - The type of enrolled DataSource Requested.
 * @param flags - Optional flags to modify the query. Currently supports:
 *   - `include_resources`: If set to true, additional resource details are included in the response.
 */
export const getAllDataSources = <T extends RegistryDataSourceTypeEnum, IncludeResources extends boolean>(type: T, flags?: { include_resources?: IncludeResources }) => {
  const queryParams = buildQueryString(flags || {})
  return makeApiCall<Array<RegistryDataSourceResponse<T, IncludeResources>>>('GET', `${URL_DS}/${type}${queryParams}`)
}

/**
 * Fetches a single data source of a specified type.
 * @param type - The type of enrolled DataSource Requested.
 * @param dataSourceId - Enrolled DataSource Id
 * @returns
 */
export const getAllDataSourcesById = (type: RegistryDataSourceTypeEnum, dataSourceId: string) =>
  makeAuthenticatedApiCall<DataSource & { resources: undefined }>('GET', `${URL_DS}/${type}/${dataSourceId}`)
