import React from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'

import { Button, Modal } from '../../../../../components'

interface Props {
  onClose: () => void
  isOpen: boolean
  isRevokeLoading: boolean
  service: string
  handleRevoke: () => void
  handleCancel: () => void
}

const RevokeModal = ({ onClose, isOpen, service, handleRevoke, handleCancel, isRevokeLoading }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-revoke-modal">
      <div>
        <div className="title" data-testid={'title'}>
          {parse(i18n.t('wallet-consent-history-modal-revoke-title'))}
        </div>
        <div className="subtitle" data-testid={'service'}>
          {parse(i18n.t('wallet-consent-history-modal-revoke-desc-1', { service: service }))}
        </div>
        <div className="subtitle" data-testid={'service2'}>
          {parse(i18n.t('wallet-consent-history-modal-revoke-desc-2', { service: service }))}
        </div>
        <div className="revoke-btn">
          <Button className={'go-back'} secondary onClick={() => handleCancel()} text={i18n.t('wallet-consent-history-modal-revoke-btn-cancel')} dataTestId={'Button-secondary'} />
          <Button
            className={'revoke'}
            isLoading={isRevokeLoading}
            onClick={handleRevoke}
            text={i18n.t('wallet-consent-history-modal-revoke-btn-revoke')}
            dataTestId={'Button-primary'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default RevokeModal
