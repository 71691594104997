import React, { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { TokenCallback } from '../../../../wallet'
import { useWallet } from '../../../../context/wallet'

import { Spinner } from '../../../../components'
import ROUTES from '../../../../routes/common/constants'
import { ECallbackError } from '../../../../wallet/types/wallet'
import { getServices } from '../../../../wallet/services/ServiceRegistry'
import { ResetStatus } from '../../../../common/constants/enum'
import usePendingConsentRequestHandler from '../../hooks/usePendingConsentRequestHandler'

// import { getTxContinueById } from '../../../../wallet/apis/user/Transactions'

const WalletAuthCallback = () => {
  const {
    Idp: { handleCallback },
  } = useWallet()

  const { handlePendingConsentRequest } = usePendingConsentRequestHandler()

  const history = useHistory()
  const { storage } = getServices()

  useEffect(() => {
    const query: TokenCallback = queryString.parse(window.location.search) as any

    const { error } = query
    const resetStatus = storage.get('reset_status') || ResetStatus.NORMAL

    if (error === ECallbackError.invalid_sessin) {
      history.push(ROUTES.LandingPage)
      return
    }
    handleCallback(query)
      .then(() => {
        if ([ResetStatus.RESET_2FA, ResetStatus.RESET_EMAIL].includes(resetStatus)) {
          history.push(ROUTES.Change2FARoute)
        } else {
          handlePendingConsentRequest(() => history.push(ROUTES.WalletProfile))
        }
      })
      .catch(async () => {
        history.push(ROUTES.LogoutRoute)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletAuthCallback
