import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from './buttons/Button';
import { HeaderI } from './ComponentInterface';

import logo from '../asset/logo.svg';
import logo2 from '../asset/MyPEI-logo-white.svg';
import Menu from './Menu';
import IconButton from './buttons/IconButton';
import { ReactComponent as IconMenu } from '../asset/icon-menu.svg';
import { ReactComponent as IconX } from '../asset/icon-x.svg';
import ROUTES from '../routes/common/constants';
import { useLanguage } from '../providers/context/LanguageContext';
import { useContext } from '../Context';

const Header = ({ logout }: HeaderI) => {
  const history = useHistory();
  const { language, toggleLanguage } = useLanguage();
  const {
    state: { config },
  } = useContext()
  const localizationEnabled = config?.['localization']?.enabled ?? false;

  const logoutClick = async () => {
    history.push(ROUTES.LogoutRoute);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const menuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const menuData = [
    { icon: '', title: 'Language', link: toggleLanguage },
    ...(logout ? [{ icon: '', title: 'Sign Out', link: logoutClick }] : []),
  ];

  return (
    <header data-testid="Header">
      <div className="header-container">
        <div className="header-main">
          <img src={logo} alt="PEI" />
          <img src={logo2} alt="PEI" />
        </div>
        <div className="header-buttons">
          {localizationEnabled && (
            <Button
              onClick={toggleLanguage}
              text={language === 'en' ? 'Français' : 'English'}
              className="header-button"
              dataTestId={language === 'en' ? 'language-switch-fr' : 'language-switch-en'}
            />
          )}
          {logout && (
            <Button
              dataTestId="signout"
              onClick={logoutClick}
              text="Sign Out"
              className="header-button header-signout"
            />
          )}
        </div>
        <IconButton onClick={menuClick} icon={isMenuOpen ? <IconX /> : <IconMenu />} />
      </div>
      <div className="header-bottom"></div>

      {isMenuOpen && <Menu data={menuData} />}
    </header>
  );
};

export default Header;