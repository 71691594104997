import React from "react";
import ReactCodeInput from "react-verification-code-input";
import { validatePasscode } from "../validate";
import { PasscodeInputI } from "../ComponentInterface";

const PasscodeInput = ({
	onChange,
	onComplete,
	onValidate,
	label,
	className,
	dataTestId,
	code,
}: PasscodeInputI) => {
	const onInputChange = (e: string) => {
		if (onChange) onChange(e);
		if (onValidate) onValidate(validatePasscode(e));
	};
	const arrayCode = code?.split("");

	return (
		<div
			className={`digit-textbox ${className || ""} `}
			data-testid={dataTestId}
		>
			<label>
				<ReactCodeInput
					autoFocus
					fields={6}
					values={arrayCode}
					className={"digit-textbox-6"}
					fieldHeight={54}
					fieldWidth={18}
					onChange={onInputChange}
					onComplete={onComplete}
				/>
				<span className="visuallyhidden">{label}</span>
			</label>
		</div>
	);
};

export default PasscodeInput;
