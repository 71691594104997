import React, { useEffect, useState } from "react";
import { Title, Text, Layout, Button, Spinner, Notification, CtaRowButton } from "../../components";
import i18n from '../../i18n';
import parse from 'html-react-parser';
import { PageI, errorI } from '../../components/ComponentInterface';
import { ReactComponent as RedExclamationIcon } from '../../asset/icon-red-exclamation.svg';
import { ReactComponent as ExclamationIcon } from '../../asset/icon-orange-exclamation.svg';
import { useTriggeredProfile } from '../../services';
import { useContext, updateProfileState, updateError } from '../../Context';
import { ReactComponent as GreenCheck } from '../../asset/icon-dark-green-check.svg';

const IdentityVerificationPage = ({ successFunc, otherRoutes: { drivingLicenseVerification, voluntaryIdVerification, healthcardVerification, reVerification}, goBackLink }: PageI) => {
    const { useProfile: profile, isLoading } = useTriggeredProfile();
    const { state: { userInfo }, dispatch } = useContext();

    // error
    const [errorObj, setErrorObj] = useState<errorI>({ error: '', errorNotification: false });

    const error500 = () => (setErrorObj({
        ...errorObj,
        error: 'error-500-1',
        errorNotification: true
      })
    );
    

    useEffect(() => { 
        // Profile Api call
        const getProfile = async () => {
          try {  
    
            // If Profile not available then get profile 
            if ((!userInfo || !userInfo?.profile || (Object.keys(userInfo?.profile).length === 0)) && !errorObj.error) {
              const { response, error } = await profile();
              
              if (response.isSuccess) {
                dispatch(updateProfileState(response.data));
              } else if (error.isUnauthorizedError) { 
                dispatch(updateError(true)) 
              } else if (error.isInternalServerError) { 
                    setErrorObj({
                        ...errorObj,
                        error: 'error-setup-500',
                        errorNotification: true
                    });
              } else error500();
            }
          } catch (e) {
            console.log('e', e);
            error500();
          }
        };
        
        getProfile();

        // eslint-disable-next-line
      }, [userInfo, errorObj]);

    
    const rowtext = (item: string) => {
        if (item === "health_card_number") {
            return i18n.t("identity-verification-health-card");
        } else if (item === "driver_license_number") {
            return i18n.t("identity-verification-driver-licence-card");
        } else if (item === "voluntary_id") {
            return i18n.t("identity-verification-voluntary-id-card");
        }

        return "";
    }

    const expiredRowtext = (item: string) => {
        if (item === "health_card_number") {
            return i18n.t("identity-verification-expired-healthcard");
        } else if (item === "driver_license_number") {
            return i18n.t("identity-verification-expired-driver-license");
        } else if (item === "voluntary_id") {
            return i18n.t("identity-verification-expired-voluntaryID");
        }

        return "";
    }

    const redirectVerification = (item: string) => {
        if (item === "health_card_number") {
            return healthcardVerification();
        } else if (item === "driver_license_number") {
            return drivingLicenseVerification();
        } else if (item === "voluntary_id") {
            return voluntaryIdVerification();
        }
    }

    const verficationIDs: Array<string> = ["health_card_number", "driver_license_number", "voluntary_id"];

    return (
        <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back-to-manage-pei-id')} title={i18n.t('identiy-verification-title')} logout >
        <Title title={i18n.t('identiy-verification-title')} />

            {
                errorObj.errorNotification 
                && 
                <Notification text={parse(i18n.t(errorObj.error))} className='error' />
            }

            {isLoading && <Spinner dark />}

            {/* Level 1  */}

            {
                (!isLoading && !userInfo?.profile?.verified_status && !userInfo?.profile?.needs_re_verification_flow && userInfo?.profile?.ial_level === "1")
                &&
                <div className="level-1-verification">
                    <i>
                    <RedExclamationIcon />
                    </i>

                    <div className="text-section">
                    {parse(i18n.t('identiy-verification-level-1'))}
                    <Button text={i18n.t('identiy-verification-btn')} onClick={successFunc} />
                    </div>
                </div>
            }

            {/* Level 3  */}
            
            {
                (!isLoading && userInfo?.profile?.verified_status === true && userInfo?.profile?.ial_level === "3")
                &&
                <div className="reverify-id-portal">
                    <Notification icon={<GreenCheck />} text={parse(i18n.t("identity-verification-complete-notification"))} />

                    <Text className="sub-title">{parse(i18n.t('identity-verification-expired-subtitle'))}</Text>

                    {
                        (userInfo?.profile && Object.keys(userInfo?.profile).length > 0)
                        &&
                        Object.keys(userInfo?.profile).map((item: string, i: number) => (
                            verficationIDs.includes(item) 
                            &&   
                            <CtaRowButton
                                key={i}
                                title={rowtext(item)}
                                ctaText={i18n.t("verified")}
                                onClick={() => redirectVerification(item)} 
                                className="row"
                            />
                        ))
                    }
                </div>
            }

            {/* Expired */}

            {
                (!userInfo?.profile?.verified_status && userInfo?.profile?.needs_re_verification_flow === true)
                &&
                <div className="reverify-id-portal">
                    <Notification icon={<ExclamationIcon />} text={parse(i18n.t("identity-verification-expired-notification"))} />

                    <Text className="sub-title">{parse(i18n.t('identity-verification-expired-subtitle'))}</Text>

                    {
                        (userInfo?.profile && Object.keys(userInfo?.profile).length > 0)
                        &&
                        Object.keys(userInfo?.profile).map((item: string, i: number) => (
                            verficationIDs.includes(item) 
                            &&   
                            <CtaRowButton
                                key={i}
                                title={expiredRowtext(item)}
                                ctaText={i18n.t("expired")}
                                onClick={reVerification}  
                                className="row"
                                isExpired={true}
                            />
                        ))
                    }
                </div>
            }
        </Layout>
    )
}

export default IdentityVerificationPage;
