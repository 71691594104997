import React from "react";
import i18n from "../../i18n";
import IconButton from "../buttons/IconButton";
import { ReactComponent as Info } from "../../asset/icon-question.svg";
import { ReactComponent as Error } from "../../asset/icon-input-error.svg";
import { TextInputI } from "../ComponentInterface";

const VerifCodeInput = ({
	value,
	onChange,
	maxLength,
	optional,
	label,
	infoClick,
	type,
	dataTestId,
	isError
}: TextInputI) => {
	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(e, e.target.value.slice(0, maxLength));
		}
	};

	return (
		<div className="textbox-form">
			<label htmlFor={label}>
				{label} {optional && <span>{`${i18n.t("optional")}`}</span>}
			</label>
			<div
				className={`textbox-form-input ${
					infoClick ? "textbox-form-input-info" : ""
				} ${isError ? "error" : ""}`}
			>
				<input
					onChange={onInputChange}
					type="text"
					min="0"
					value={value}
					maxLength={maxLength}
					id={label}
					formNoValidate
					data-testid={dataTestId}
				/>
				{infoClick && (
					<IconButton
						onClick={infoClick}
						icon={<Info />}
						type={type}
					/>
				)}
				{isError && !infoClick && <div className="error-icon-container"><Error className="error-icon" /></div>}
			</div>
		</div>
	);
};

export default VerifCodeInput;
