import parse from 'html-react-parser';

/**
 * Function to replace placeholders in an HTML string with actual values and parse it.
 * @param {string} html - The HTML string with placeholders like {placeholder}.
 * @param {Object} values - An object containing placeholder-value pairs.
 * @returns {React.ReactNode} - The parsed React element with placeholders replaced by values.
 */
interface PlaceholderValues {
    [key: string]: string;
}

function interpolateParse(html: string, values: PlaceholderValues): React.ReactNode {
    const interpolatedHtml = html.replace(/{(.*?)}/g, (_, key) => values[key.trim()] || '');
    return parse(interpolatedHtml);
}

export default interpolateParse;