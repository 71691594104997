import { useState } from 'react'
import { AuthenticatorI } from '../../../../../components/ComponentInterface'
import { useTriggeredAuthenticatorMethod } from '../../../../../services'

const useAccountInformation = () => {
  const { useAuthenticatorMethod: authenticatorMethod } = useTriggeredAuthenticatorMethod()
  const [authMethod, setAuthMethod] = useState<AuthenticatorI>({})

  const getAuthMethod = async () => {
    if (authMethod?.description) return
    try {
      const { response } = await authenticatorMethod()
      if (response.isSuccess) {
        const data = response.data
        setAuthMethod(data)
      } else {
      }
    } catch (e) {
      console.log('e', e)
    }
  }

  return { authMethod, getAuthMethod }
}

export default useAccountInformation
