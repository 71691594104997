import React from "react";
import i18n from "../i18n";
import { LinkButton } from ".";
import { useContext } from "../Context";
import { FooterI } from "./ComponentInterface";
import parse from "html-react-parser";

const Footer = ({ moreLinks }: FooterI) => {
  const {
    state: {
      config: {
        ota_web_base_url,
        links: { gpei },
      },
    },
  } = useContext();
  const termsClick = () => window.open(`${ota_web_base_url}/terms-of-use`);
  const privacyClick = () => window.open(`${ota_web_base_url}/privacy-policy`);
  const noticeOfCollectionClick = () => window.open(`${ota_web_base_url}/privacy-policy`);

  return (
    <footer>
      <div className={`footer-container ${moreLinks ? "more-links" : ""}`}>
        <div className="left-footer">
          {/* <i>
            <Info />
          </i> */}
          {parse(i18n.t("footer-right", { gpei }))}
        </div>
        {moreLinks && (
          <div className="lower-footer">
            <LinkButton onClick={termsClick} text={`${i18n.t("footer-terms-terms-of-use")}`} />
            <LinkButton
              onClick={noticeOfCollectionClick}
              text={i18n.t("footer-terms-notice-of-collection")}
            />
            <LinkButton onClick={privacyClick} text={i18n.t("footer-terms-privacy-policy")} />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
