import axios from 'axios'
import { generateOAuthUrl } from '../utils/OAuthUtils'
import { TokenCallback } from '..'
import { getOpenIdConfiguration } from '../utils/WellKnown'
import { getServices } from './ServiceRegistry'
import { calculateExpirationTime } from '../utils/CalculateExpirationTime'

interface TokenExchangeResponse {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
}

export const Idp = () => {
  const { config, storage } = getServices()

  const createLoginUrl = async (idp_authenticator_id: string, redirect_uri: string) => {
    const { oauthUrl, codeVerifier, state } = await generateOAuthUrl({
      scopePrefix: 'idp',
      identifier: idp_authenticator_id,
      responseType: 'code',
      redirect_uri,
    })

    storage.set('oauth_state', state)
    storage.set('code_verifier', codeVerifier)
    storage.set('redirect_uri', redirect_uri)

    return oauthUrl
  }

  /**
   * Handles the PKCE return from the IDP flow. Returns back an access_token, in addition the access_token is also saved on storage[session] key.
   */
  const handleCallback = async ({ code, state }: TokenCallback) => {
    const savedState = storage.get('oauth_state')

    if (state !== savedState) {
      throw new Error('State does not match')
    }

    const { access_token, expires_in } = await requestToken(code)

    storage.set('session', access_token)
    storage.set('session_expiry_token', calculateExpirationTime(expires_in).toUTCString())
    storage.delete('oauth_state')
    storage.delete('code_verifier')

    return access_token
  }
  // TODO move this out into an api file
  const requestToken = async (authCode: string): Promise<TokenExchangeResponse> => {
    const { token_endpoint } = await getOpenIdConfiguration()
    const { client_id } = config

    const redirect_uri = storage.get('redirect_uri')
    const code_verifier = storage.get('code_verifier')

    try {
      const response = await axios.post<TokenExchangeResponse>(
        token_endpoint,
        {
          grant_type: 'authorization_code',
          code: authCode,
          redirect_uri: redirect_uri,
          code_verifier: code_verifier,
          client_id: client_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      return response.data
    } catch (error: any) {
      throw new Error(error.response)
    }
  }

  return { createLoginUrl, handleCallback }
}
