import { useCallback } from 'react'
import { useWallet } from '../../../context/wallet'

import ROUTES from '../../../routes/common/constants'
import { useContext } from '../../../Context'
import LocalStorage from '../../../services/LocalStorage'

const useIdp = () => {
  const { Idp } = useWallet()

  const {
    state: {
      config: { wallet_config },
    },
  } = useContext()

  const { idp_authenticator_id } = wallet_config

  const loginIdp = useCallback(async () => {
    const url = await Idp.createLoginUrl(idp_authenticator_id, `${window.location.origin}${ROUTES.WalletCallback}`)

    window.location.href = url
  }, [idp_authenticator_id, Idp])

  const isAuthenticated = !!LocalStorage.get('session')

  return { loginIdp, isAuthenticated }
}

export default useIdp
