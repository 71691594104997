import React, { useState } from 'react'
import { AccessPeiForm, CreateIdEmailForm, Layout, Text, Title } from '../../components'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { PageI, errorI } from '../../components/ComponentInterface'
import ROUTES from '../../routes/common/constants'

const CreateIdEmailPage = ({ successFunc, goBackLink, otherRoutes: { inPersonAccount } }: PageI) => {
  // For Verification Code errors
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  return (
    <Layout
      header
      footer
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      title={i18n.t('create-id-email-title')}
      className={'create-id-email progress-bar'}
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={1}
    >
      <Title title={i18n.t('create-id-email-title')} />
      <Text className="heading">{i18n.t('create-id-email-description')}</Text>

      <CreateIdEmailForm successFunc={successFunc} accesFormErrorObj={errorObj} />
      <Text className="withLink">
        {parse(
          i18n.t('create-id-email-have-account', {
            link: `${ROUTES.SigninRoute}`,
          }),
        )}
      </Text>

      <AccessPeiForm successFunc={inPersonAccount} previousRoute={ROUTES.CreatePeiIdRoute} errorObj={errorObj} setErrorObj={setErrorObj} />
    </Layout>
  )
}

export default CreateIdEmailPage
