import React from 'react'
import { Layout, Text, Title } from '../../components'
import AuthSelector from '../../components/authSelector/AuthSelector'
import { PageI } from '../../components/ComponentInterface'
import i18n from '../../i18n'

const Choose2FAPage = ({ goBackLink, otherRoutes: { totpRoute, smsRoute, emailRoute } }: PageI) => {
  return (
    <Layout
      header
      footer
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      title={i18n.t('add-auth-title')}
      className={'choose-2FA progress-bar'}
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={3}
    >
      <Title title={i18n.t('add-auth-title')} />
      <Text className="lead-text">{i18n.t('add-auth-description')}</Text>
      <AuthSelector totpRoute={totpRoute} smsRoute={smsRoute} emailRoute={emailRoute} usingEmail={true} />
    </Layout>
  )
}

export default Choose2FAPage
