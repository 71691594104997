import { ReactComponent as IconGreenChevronDown } from '../../asset/icon-green-chevron-down.svg'
import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useEffect, useId, useRef, useState } from 'react'

type AccordionContextType = [string, boolean, Dispatch<SetStateAction<boolean>>]
const AccordionContext = createContext<AccordionContextType>([
  'text-id', // Default accordionId
  false, // Default accordionOpen value
  () => null, // Default setAccordionOpen value
])

type AccordionTriggerProps = PropsWithChildren & {
  dataTestId?: string
}

export const AccordionTrigger: FC<AccordionTriggerProps> = ({ children, dataTestId = 'accordion-trigger-[index]' }) => {
  const [accordionId, accordionOpen, setAccordionOpen] = useContext(AccordionContext)
  return (
    <h3>
      <button
        className="accordion-trigger"
        onClick={(e) => {
          e.preventDefault()
          setAccordionOpen(!accordionOpen)
        }}
        aria-expanded={accordionOpen}
        aria-controls={`accordion-content-${accordionId}`}
        id={`accordion-title-${accordionId}`}
        data-testid={dataTestId}
      >
        {children}
        <div className="chevron-container">
          <IconGreenChevronDown className={`chevron-icon  ${accordionOpen ? 'rotate-180' : ''}`} />
        </div>
      </button>
    </h3>
  )
}

export const AccordionContent: FC<PropsWithChildren> = ({ children }) => {
  const [accordionId, accordionOpen] = useContext(AccordionContext)
  const contentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (accordionOpen) {
      contentRef.current?.focus()
    }
  }, [accordionId, accordionOpen])
  return (
    <div
      ref={contentRef}
      id={`accordion-content-${accordionId}`}
      role="region"
      aria-labelledby={`accordion-title-${accordionId}`}
      aria-hidden={!accordionOpen}
      className={`accordion-content ${accordionOpen ? 'show' : ''}`}
    >
      <div className="accordion-content-container">
        <div className="accordion-content-inner">{children}</div>
      </div>
    </div>
  )
}

export const Accordion: FC<PropsWithChildren> = ({ children }) => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false)
  // Generate a unique ID for each accordion instance to improve accessibility and ensure unique associations
  const accordionId = useId()

  return (
    <AccordionContext.Provider value={[accordionId, accordionOpen, setAccordionOpen]}>
      <div className="accordion">{children}</div>
    </AccordionContext.Provider>
  )
}
