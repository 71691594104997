import axios from 'axios';

export const get = async (url: string, config = { params: {} }) =>
  await axios.get(url, config)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) return error.response;
      return false
    })

export const post = (url: string, body = {}) =>
  axios.post(url, body)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) return error.response;
      return false
    })

export const put = (url: string, body = {}) =>
  axios.put(url, body)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) return error.response;
      return false
    })

export default axios;
