import React, { useEffect, useState } from "react";
import {
	Button,
	EmailInput,
	Layout,
	Notification,
	Text,
	Title,
} from "../../components";
import i18n from "../../i18n";
import parse from "html-react-parser";
import { useTriggeredPasswordReset } from "../../services";
import { useContext, updateForgotEmail, updateError } from "../../Context";
import { PageI } from "../../components/ComponentInterface";

const ForgotPasswordPage = ({ successFunc, goBackLink }: PageI) => {
	const { dispatch, state: {userInfo:{ forgot_email }} } = useContext();

	const { usePasswordReset: updatePasswordReset, isLoading } =
		useTriggeredPasswordReset();

	const [email, setEmail] = useState(forgot_email ?? '');
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [error, setError] = useState("");

	useEffect(()=>{
		!!forgot_email && setIsValidEmail(true)
	}, [forgot_email])

	const onSuccess = async () => {
		const { response, error } = await updatePasswordReset({
			data: { email },
		});
		if (response.isSuccess) {
			dispatch(updateForgotEmail(email));
			successFunc && successFunc();
		} else if (error.isUnauthorizedError || error.isForbiddenError) {
			dispatch(updateError(true));
		} else {
			setError("error-500-1");
		}
	};
	return (
		<Layout
			header
			footer
			title={i18n.t("forgot-password-title")}
			backBtnCallback={goBackLink}
			backText={i18n.t("back")}
		>
			<Title title={i18n.t("forgot-password-title")} />
			<Text>{parse(i18n.t("forgot-password-description"))}</Text>
			<form
				onSubmit={(e) => e.preventDefault()}
				autoComplete="off"
				className="forgot-password-form"
			>
				{error && (
					<Notification
						text={parse(i18n.t(error))}
						className="error"
						dataTestId="banner-error"
					/>
				)}
				<EmailInput
					onChange={(e: any) => setEmail(e.target.value)}
					value={email}
					label={i18n.t("email")}
					onValidate={setIsValidEmail}
					required
					dataTestId="form-email"
				/>
				<Button
					onClick={onSuccess}
					text={i18n.t("send")}
					disabled={!isValidEmail}
					isLoading={isLoading}
					dataTestId="Button-primary"
				/>
			</form>
		</Layout>
	);
};

export default ForgotPasswordPage;
