import React from 'react'
import { NotificationI } from './ComponentInterface'

const Notification = ({ icon, text, className, dataTestId, notificationRef }: NotificationI) => (
  <div className={`notification ${icon ? 'icon' : ''} ${className ? className : ''}`} ref={notificationRef} data-testid={dataTestId}>
    {icon && <i>{icon}</i>}
    {text}
  </div>
)

export default Notification
