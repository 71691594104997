import React from 'react'
import i18n from '../../i18n'
import IconButton from '../buttons/IconButton'
import { ReactComponent as Info } from '../../asset/icon-question.svg'
import iconError from '../../asset/icon-input-error.svg'
import { validatePhoneInput } from '../validate'
import { PhoneInputI } from '../ComponentInterface'

const PhoneInput = ({ value, onChange, maxLength, optional, label, infoClick, type, onValidate, required, placeholder, name, dataTestId, hasError, errorMessage }: PhoneInputI) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e)
    if (onValidate) onValidate(validatePhoneInput(e.target.value || ''))
  }
  return (
    <div className="textbox-form">
      <label htmlFor={label}>
        <strong>{label}</strong> {optional && <span>{`${i18n.t('optional')}`}</span>}
        {required && ' *'}
      </label>
      <div className={`textbox-form-input ${infoClick ? 'textbox-form-input-info' : ''}`}>
        <input
          onChange={onInputChange}
          type="text"
          inputMode="numeric"
          min="0"
          pattern="[0-9]*"
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          id={label}
          formNoValidate
          name={name}
          data-testid={dataTestId}
          className={hasError ? 'textbox-form-input-error-alert' : ''}
          style={hasError ? { backgroundImage: `url(${iconError})` } : {}}
        />

        {hasError && <p className="textbox-form-input-error-text">{errorMessage}</p>}
        {infoClick && <IconButton onClick={infoClick} icon={<Info />} type={type} />}
      </div>
    </div>
  )
}

export default PhoneInput
