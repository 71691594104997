import { updateError, useContext } from '../../../Context'
import { useTriggeredSwat } from '../../../services'

const useSWAT = () => {
  const { useSwatIdentity: swat } = useTriggeredSwat()
  const { dispatch } = useContext()

  const getSwat = async () => {
    try {
      const { response } = await swat()
      if (response.isSuccess) {
        return response.data
      }
    } catch (e) {
      dispatch(updateError(true))
    }
  }

  return { getSwat }
}

export default useSWAT
