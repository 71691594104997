import React, { FC, useCallback, useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import { IconListItem, Text } from '../../components'
import { ReactComponent as CopyIcon } from '../../asset/icon-copy.svg'
import parse from 'html-react-parser'
import { Tooltip } from 'react-tooltip'
import i18n from '../../i18n'

/**
 * The name of the issuing party that shows up in the authenticator app.
 *
 * The full entry shows up as `{issuer}:{account-identifier/label}`
 *
 * See https://www.ietf.org/archive/id/draft-linuxgemini-otpauth-uri-00.html#name-label-entry-required
 */
const QRIssuer = 'MyPEI'

/**
 * The default identifier to be shown if an identifier isn't provided with the authenticator secret
 */
const DefaultQRIdentifier = 'Account'

type QRSectionProps = {
  authenticatorSecret: string
  identifier?: string
}

const QRSection: FC<QRSectionProps> = ({ authenticatorSecret, identifier = DefaultQRIdentifier }) => {
  const [textCopied, setTextCopied] = useState(false)
  const qrValue = useMemo(() => `otpauth://totp/${identifier}?secret=${authenticatorSecret}&issuer=${QRIssuer}`, [authenticatorSecret, identifier])
  const handleCopyClick = useCallback(() => {
    if (authenticatorSecret) {
      navigator.clipboard
        .writeText(authenticatorSecret)
        .then(() => {
          setTextCopied(true)
          setTimeout(() => {
            setTextCopied(false)
          }, 3000)
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
        })
    }
  }, [authenticatorSecret])
  return (
    <>
      <QRCode size={175} value={qrValue} />

      <div className="instruction-box">
        <Text>{parse(i18n.t('setup-authenticator-app-qr-page-keycode'))}</Text>
        <Text dataTestId="code">{authenticatorSecret}</Text>
        <IconListItem logo={<CopyIcon />} dataTestId="tap-copy">
          <button data-tooltip-id="select" onClick={handleCopyClick}>{`${i18n.t('setup-authenticator-app-tap-to-copy')}`}</button>
          {textCopied ? <Tooltip id={'select'} content={i18n.t('Copied')} place={'right'} isOpen={textCopied} /> : null}
        </IconListItem>
      </div>
    </>
  )
}

export default QRSection
