import parse from 'html-react-parser'
import React, { FC } from 'react'
import { ReactComponent as ComputerIcon } from '../../asset/icon-computer-green.svg'
import { ReactComponent as PhoneIcon } from '../../asset/icon-phone-green.svg'
import { ReactComponent as PlusIcon } from '../../asset/icon-plus-green.svg'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { Button, IconListItem, LinkButton, Spinner, Text } from '../../components'
import i18n from '../../i18n'
import ROUTES from '../../routes/common/constants'
import QRSection from './QRSection'

type AppAuthSetupStep2Props = {
  isQRLoading?: boolean
  identifier?: string
  authenticatorSecret: string
  onSuccess?: () => void
  onBack?: () => void
}

const AppAuthSetupStep2: FC<AppAuthSetupStep2Props> = ({ isQRLoading, authenticatorSecret, identifier, onSuccess, onBack }) => {
  return (
    <div className="content-container">
      <div>
        <Text className="subtitle" dataTestId="lead-text">
          {parse(i18n.t('setup-authenticator-app-qr-page-subtitle'))}
        </Text>
        <div className="content-body-container">
          <div className="left">
            <IconListItem logo={<PlusIcon />} dataTestId="step-2">
              <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-2'))}</Text>
            </IconListItem>
            <div>
              <IconListItem logo={<ComputerIcon />} dataTestId="step-3-option1">
                <div>
                  <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-3-option1'))}</Text>
                </div>
              </IconListItem>
              <Text className="or-text">{parse(i18n.t('or'))}</Text>
              <IconListItem logo={<PhoneIcon />} dataTestId="step-3-option2">
                <Text>{parse(i18n.t('setup-authenticator-app-qr-page-step-3-option2'))}</Text>
              </IconListItem>
            </div>
            <div className="links-container">
              <IconListItem logo={<ShowMeIcon />} dataTestId="Show-how">
                <LinkButton target="_blank" text={i18n.t('setup-authenticator-app-show-me-link')} url={ROUTES.AccountSetupHelpRoute} />
              </IconListItem>
            </div>
            <div className="qr-code">{isQRLoading ? <Spinner dark /> : <QRSection authenticatorSecret={authenticatorSecret} identifier={identifier} />}</div>
            <div className="button-container-2">
              <Button secondary text={i18n.t('setup-authenticator-app-back')} onClick={onBack} dataTestId="Button-secondary" />
              <Button text={i18n.t('continue')} onClick={onSuccess} dataTestId="Button-primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppAuthSetupStep2
