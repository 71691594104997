import { createContext, useContext } from 'react'

import { createWalletClient } from '../../wallet'

interface WalletCtxDefaultValueProps {
  Idp: ReturnType<typeof createWalletClient>['Idp']
  DataSource: ReturnType<typeof createWalletClient>['DataSource']
  Consent: ReturnType<typeof createWalletClient>['Consent']
  Wallet: ReturnType<typeof createWalletClient>['Wallet']
}

export const WalletContext = createContext<WalletCtxDefaultValueProps | undefined>(undefined)

export const useWallet = () => {
  const context = useContext(WalletContext)

  if (!context) {
    throw new Error('App tree is missing wallet provider')
  }
  return context
}
