import React from 'react'
import useScrollToHash from '../../../../common/hooks/useScrollToHash'
import { Layout, Title } from '../../../../components'
import { Accordion, AccordionContent, AccordionTrigger } from '../../../../components/accordion/Accordion'
import i18n from '../../../../i18n'

type AccordionType = {
  id?: string
  title: string
  content: string
}

type AccordionGroup = {
  id?: string
  title?: string
  notice?: string
  accordions: Array<AccordionType>
}

type AccordionCategory = {
  'category-title': string
  groups: Array<AccordionGroup>
}

const openClosestAccordion = (element: HTMLElement) => {
  const button = element.closest('button')
  if (!!button && button.getAttribute('aria-expanded') === 'false') button.click()
}

const AccountSetupHelpPage = () => {
  useScrollToHash({ beforeScroll: openClosestAccordion, delay: 300 })

  const categories = i18n.t('account-setup-help-accordion-categories', { returnObjects: true }) as Array<AccordionCategory> | string
  if (typeof categories === 'string') {
    return <div>Error when fetching accordion categories. Check json files and make sure "account-setup-help-accordion-categories" exists</div>
  }

  return (
    <Layout header footer>
      <div className="account-setup-help-container">
        <Title dataTestid="title" title={i18n.t('account-setup-help-title')} />
        {categories.map((category, categoryIndex) => (
          <div className="category" key={`category-${categoryIndex}`}>
            <h2 className="category-title" dangerouslySetInnerHTML={{ __html: category['category-title'] }} />
            {category.groups.map((group, groupIndex) => (
              <div className="category-group" key={`group-${groupIndex}`} id={group.id}>
                {!!group.title && <h3 className="group-title" dangerouslySetInnerHTML={{ __html: group.title }} />}
                {!!group.notice && <div className="group-notice" dangerouslySetInnerHTML={{ __html: group.notice }} />}
                <div className="accordions">
                  {group.accordions.map((accordion, accordionIndex) => (
                    <Accordion key={`accordion-${accordionIndex}`}>
                      <AccordionTrigger dataTestId={`accordion-category-${categoryIndex + 1}-group-${groupIndex + 1}-trigger-${accordionIndex + 1}`}>
                        <div dangerouslySetInnerHTML={{ __html: accordion.title }} />
                      </AccordionTrigger>
                      <AccordionContent>
                        <div dangerouslySetInnerHTML={{ __html: accordion.content }} />
                      </AccordionContent>
                    </Accordion>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default AccountSetupHelpPage
