import React from "react";
import { IconButtonI } from "../ComponentInterface";

const IconButton = ({ title='info-btn', className='info-btn', onClick, type, icon, dataTestId }: IconButtonI) => (
	<button
		title={title}
		className={className}
		data-testid={dataTestId}
		onClick={(e) => {
			onClick && onClick(e, type);
		}}
	>
		<i>{icon}</i>
	</button>
);

export default IconButton;
