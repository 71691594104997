import React, { FC, PropsWithChildren } from 'react'
import { CardI } from '../ComponentInterface'
import { ReactComponent as LightbulbIcon } from '../../asset/lightbulb-grey.svg'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'

type WrapInLinkProps = PropsWithChildren & {
  url?: string
  className?: string
  dataTestId?: string
}
const WrapInLink: FC<WrapInLinkProps> = ({ url, className, dataTestId, children }) =>
  !!url ? (
    <Link to={url} className={className} data-testid={dataTestId}>
      {children}
    </Link>
  ) : (
    <div className={className} data-testid={dataTestId}>
      {children}
    </div>
  )

const Card = ({ icon, url, title, description, reminder, className, dataTestId }: CardI) => {
  return (
    <WrapInLink url={url} className={`card ${className ? className : ''}`} dataTestId={dataTestId}>
      <div className="body">
        <h4 data-testid="heading">{title}</h4>
        <div className="description" data-testid="text">
          {parse(`${description}`)}
        </div>
        {reminder && (
          <div className="reminder">
            <div className="body-reminder">
              <LightbulbIcon />
              <p>{reminder}</p>
            </div>
          </div>
        )}
      </div>
      {!!icon && <i className="icon">{icon}</i>}
    </WrapInLink>
  )
}

export default Card
