import React, { FC } from 'react'
import { Button, IconListItem, PasscodeForm, Text, Notification } from '../index'
import i18n from '../../i18n'
import parse from 'html-react-parser'
import { ReactComponent as Phone } from '../../asset/icon_phone_code.svg'

type SmsAuthSetupStep2Props = {
  onSuccess?: () => void
  onBack?: () => void
}

const SmsAuthSetupStep2: FC<SmsAuthSetupStep2Props> = ({ onSuccess, onBack }) => {
  return (
    <>
      <IconListItem logo={<Phone />}>
        <Text>{parse(i18n.t('setup-sms-description-list-step-2'))}</Text>
      </IconListItem>
      <div className="code-form">
        <PasscodeForm successFunc={onSuccess} apiType="post" twoFaMethod="SMS" />
        <Button secondary text={i18n.t('go-back')} onClick={onBack} dataTestId="Button-secondary" />
      </div>
      <Notification text={parse(i18n.t('auth-alert-sms'))} className="notice-box" />
    </>
  )
}

export default SmsAuthSetupStep2
