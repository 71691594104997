import parse from 'html-react-parser'
import React from 'react'
import { Layout, Text, Title } from '../../components'
import { PageI } from '../../components/ComponentInterface'
import i18n from '../../i18n'

import { useHistory } from 'react-router-dom'
import EmailAuthSetup from '../../components/emailAuthSetup'
import ROUTES from '../../routes/common/constants'
import { useTriggeredAuthenticatorRegister } from '../../services'

const AddEmailAuthPage = ({ goBackLink, successFunc }: PageI) => {
  const { useAuthenticatorRegister: authenticatorRegister, isLoading } = useTriggeredAuthenticatorRegister()
  const userEmail = localStorage.getItem('userEmail')
  const history = useHistory()

  const handleRegisterAuthenticator = async () => {
    const { response, error } = await authenticatorRegister({
      data: { method: 'EMAIL' },
    })
    successErrorResponse(response, error)
  }

  const successErrorResponse = (response: any, error: any) => {
    if (response.isSuccess) {
      successFunc?.()
      return
    }

    switch (true) {
      case error.isUnauthorizedError:
        history.push(ROUTES.LogoutRoute)
        break
      default:
        break
    }
  }

  return (
    <Layout
      header
      footer
      title={i18n.t('add-email-auth-title')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="choose-2FA add-email-auth email-auth progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={4}
    >
      <Title title={i18n.t('add-email-auth-title')} />
      <Text className="withVar lead-text" dataTestId="lead">
        {parse(
          i18n.t('add-email-auth-description-1', {
            var: userEmail ?? parse(i18n.t('example-email')),
          }),
        )}
      </Text>
      <EmailAuthSetup isLoading={isLoading} onSubmit={handleRegisterAuthenticator} onBack={goBackLink} />
    </Layout>
  )
}

export default AddEmailAuthPage
