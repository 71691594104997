import React, { FC, Dispatch, SetStateAction, useEffect } from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

import { useTriggeredProfileUpdateChangePassword } from '../../../../../services'

import { Button, PasswordInput, Notification } from '../../../../../components'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { ManageAccountError } from '../../../../../common/constants'

import { ReactComponent as Warning } from '../../../../../asset/icon-warning.svg'


interface PasswordChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: any) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdatePasswordForm: () => void
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  setPasswordUpdated: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const PasswordChangeForm: FC<PasswordChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdatePasswordForm,
  setInfoSaved,
  setPasswordUpdated,
  dataTestId,
}) => {
  const { useUpdateProfileChangePassword: updateProfileChangePassword, isLoading: changePasswordLoading } = useTriggeredProfileUpdateChangePassword()

  const profileInputOnChangeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedProfileData = {
      ...profileData,
      [e.target.name]: e.target.value.slice(0, 150),
    }

    setProfileData(updatedProfileData)
  }

  const cancelUpdatePassword = () => {
    //close the password form
    toggleUpdatePasswordForm()
    //reset the password form
    setProfileData({
      ...profileData,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    //reset the error messages
    setErrorObj({ errorNotification: false, errorCurrentPassword: false, errorPassword: false, errorConfirmPassword: false, error: '' })
  }

  const updatePassword = async () => {
    setErrorObj({ errorNotification: false, errorCurrentPassword: false, errorPassword: false, errorConfirmPassword: false, error: '' })

    if (!profileData.currentPassword) {
      setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.PASSWORD_REQUIREMENT, errorCurrentPassword: true }))
      return
    }

    if (!profileData.newPassword || validInputs.isValidNewPassword === false) {
      setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.PASSWORD_REQUIREMENT, errorPassword: true }))
      return
    }

    if (!profileData.confirmPassword || validInputs.isValidConfirmPassword === false) {
      setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.PASSWORD_REQUIREMENT, errorConfirmPassword: true }))
      return
    }

    if (!(profileData.newPassword === profileData.confirmPassword)) {
      setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.PASSWORD_DO_NOT_MATCH, errorConfirmPassword: true, errorPassword: true }))
      return
    }

    if (profileData.newPassword === profileData.confirmPassword && profileData.newPassword !== '' && profileData.confirmPassword !== '') {
      const { response, error } = await updateProfileChangePassword({
        data: {
          new_password: profileData.newPassword,
          current_password: profileData.currentPassword,
        },
      })

      if (response.isSuccess) {
        setPasswordUpdated(true)
        toggleUpdatePasswordForm()
        setInfoSaved(true)

        setProfileData({
          ...profileData,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        })

        setErrorObj({ errorNotification: false, errorPassword: false, errorCurrentPassword: false, error: '' })
      } else if (error.response.data.error === 'invalid_password') {
        setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.PASSWORD_INCORRECT, errorCurrentPassword: true }))
        setInfoSaved(false)
      } else {
        setErrorObj((prev: any) => ({ ...prev, error: ManageAccountError.INTERNAL_SERVER_ERROR }))
        setInfoSaved(false)
      }
    } else {
      setErrorObj((prev: any) => ({ ...prev, errorPassword: true, error: ManageAccountError.PASSWORD_REQUIREMENT }))
    }
  }

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdatePasswordBtn:
        Boolean(validInputs.isValidPassword) &&
        Boolean(validInputs.isValidNewPassword) &&
        Boolean(validInputs.isValidConfirmPassword) &&
        profileData.newPassword === profileData.confirmPassword,
    })
    // eslint-disable-next-line
  }, [
    validInputs.isValidPassword,
    validInputs.isValidNewPassword,
    validInputs.isValidConfirmPassword,
    profileData.currentPassword,
    profileData.newPassword,
    profileData.confirmPassword,
  ])

  return (
    <div className="textbox-form-container" data-testid={dataTestId}>
      {errorObj.error && (errorObj.error === ManageAccountError.PASSWORD_REQUIREMENT || errorObj.error === ManageAccountError.PASSWORD_INCORRECT) && (
        <Notification icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="banner-error" />
      )}
      <PasswordInput
        name="currentPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.currentPassword}
        label={i18n.t('profile-information-current-password')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidPassword: e })}
        required
        isFocused={Boolean(profileData?.currentPassword)}
        isError={errorObj.errorCurrentPassword}
        dataTestId="form-pw-current"
      />
      <PasswordInput
        name="newPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.newPassword}
        label={i18n.t('profile-information-create-new-password')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidNewPassword: e })}
        description={parse(i18n.t('password-creation-description'))}
        required
        isFocused={Boolean(profileData?.newPassword)}
        isError={errorObj.errorPassword}
        dataTestId="form-pw-new"
      />
      <PasswordInput
        name="confirmPassword"
        minLength={12}
        onChange={profileInputOnChangeFunc}
        value={profileData?.confirmPassword}
        label={i18n.t('profile-information-confirm-new-password')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidConfirmPassword: e })}
        required
        isFocused={Boolean(profileData?.confirmPassword)}
        isError={errorObj.errorConfirmPassword}
        dataTestId="form-pw-confirm"
      />
      {errorObj.error && (errorObj.error === ManageAccountError.PASSWORD_DO_NOT_MATCH) && (
        <div className='error-container'>
          <Notification icon={<Warning />} text={parse(i18n.t(errorObj.error))} className="error" dataTestId="form-field-error" />
        </div>
      )}
      <div className="btn-group">
        <Button dataTestId="button-cancel" secondary onClick={cancelUpdatePassword} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" onClick={updatePassword} text={i18n.t('save')} isLoading={changePasswordLoading} />
      </div>
    </div>
  )
}

export default PasswordChangeForm
