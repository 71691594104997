import { AllDataSourceAccountsResponse, DataSourceAccountByIdQueryFlags, DataSourceQueryFlags } from '../../types/datasource'
import { makeAuthenticatedApiCall } from '../../utils/ApiHelper'
import { buildQueryString } from '../../utils/BuildQueryString'

const URL_DS_ACCOUNTS = '/me/ds-accounts'

export const getMyDataSourceAccounts = <Flags extends DataSourceQueryFlags>(flags?: Flags) => {
  const queryParams = buildQueryString(flags || {})
  return makeAuthenticatedApiCall<Array<AllDataSourceAccountsResponse<Flags>>>('GET', `${URL_DS_ACCOUNTS}${queryParams}`)
}

/**
 * Retrieves a data source account by an id for an authenticated user with optional query flags to customize the response.
 *
 * @param {Flags} flags - An object containing query flags:
 *  - `include_dsa_resources`: If true, the response includes details of data source account resource authorizations.
 */
export const getMyDataSourceAccountsById = <Flags extends DataSourceAccountByIdQueryFlags>(dsAccountId: string, flags?: Flags) => {
  const queryParams = buildQueryString(flags || {})
  return makeAuthenticatedApiCall<AllDataSourceAccountsResponse<Flags>>('GET', `${URL_DS_ACCOUNTS}/${dsAccountId}${queryParams}`)
}
