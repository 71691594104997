export enum VerifyStatus {
  VERIFIED = 'verified',
  PENDING = 'pending',
}

export enum ResetStatus {
  RESET_EMAIL = 'email_reset',
  RESET_2FA = '2fa_reset',
  NORMAL = 'normal',
}
