import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useIdp from '../../hooks/useIdp'

import { Spinner } from '../../../../components'
import ROUTES from '../../../../routes/common/constants'

const WalletLoginPage: FC = () => {
  const { loginIdp, isAuthenticated } = useIdp()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) {
      history.push(ROUTES.WalletProfile)
    } else {
      loginIdp()
    }
  }, [isAuthenticated, history, loginIdp])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletLoginPage
