import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { NameInput, Button, Text } from '../../../../../components'
import { errorI, InputValidateI, ProfileDataI } from '../../../../../components/ComponentInterface'
import { useContext, updateProfileState, updateError } from '../../../../../Context'
import { useTriggeredProfileUpdateName } from '../../../../../services'
import i18n from '../../../../../i18n'
import { nameRegex } from '../../../../../utils'
import { ManageAccountError } from '../../../../../common/constants'

interface NameChangeFormProps {
  profileData: ProfileDataI
  setProfileData: (data: ProfileDataI) => void
  errorObj: errorI
  setErrorObj: (error: errorI) => void
  validInputs: InputValidateI
  setValidateInputs: (input: InputValidateI) => void
  toggleUpdateNameForm: () => void
  infoSaved: Boolean
  setInfoSaved: Dispatch<SetStateAction<boolean>>
  dataTestId?: string
}

const NameChangeForm: FC<NameChangeFormProps> = ({
  profileData,
  setProfileData,
  errorObj,
  setErrorObj,
  validInputs,
  setValidateInputs,
  toggleUpdateNameForm,
  infoSaved,
  setInfoSaved,
  dataTestId,
}) => {
  const { useUpdateProfileUpdateName: updateProfileName, isLoading: updateProfileLoading } = useTriggeredProfileUpdateName()
  const { dispatch } = useContext()

  const [name, setName] = useState({
    first_name: profileData?.firstName,
    middle_name: profileData?.middleName,
    last_name: profileData?.lastName,
  })

  const profileInputOnchangeFunc = (e: any) => {
    if (!nameRegex(e.target.value)) return

    const updatedProfileData = {
      ...name,
      [e.target.name]: e.target.value.slice(0, 150),
    }

    setName(updatedProfileData)
  }

  const updateName = async () => {
    const { first_name, middle_name, last_name } = name

    const { response, error } = await updateProfileName({
      data: {
        first_name: first_name || null,
        middle_name: middle_name || null,
        last_name,
      },
    })

    if (response.isSuccess) {
      setInfoSaved(true)
      setProfileData({
        ...profileData,
        firstName: response.data.first_name,
        middleName: response.data.middle_name,
        lastName: response.data.last_name,
      })

      setErrorObj({
        ...errorObj,
        errorNotification: false,
        error: '',
      })
      dispatch(updateProfileState(response.data))
      toggleUpdateNameForm()
    } else if (error.isUnauthorizedError) {
      dispatch(updateError(true))
      setInfoSaved(false)
    } else {
      setErrorObj({
        ...errorObj,
        error: ManageAccountError.ERROR_LEGAL_NAME,
      })
      setInfoSaved(false)
    }
  }

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidUpdateNameBtn: Boolean(validInputs.isValidLastName),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidLastName])

  return (
    <div data-testid={dataTestId} className={`textbox-form-container-2 ${infoSaved ? 'activated-name-form' : ''}`}>
      <NameInput
        type="name-loa3"
        name="first_name"
        onChange={profileInputOnchangeFunc}
        value={name?.first_name}
        maxLength={150}
        minLength={1}
        label={i18n.t('name')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidFirstName: e })}
        isFocused={Boolean(name?.first_name)}
        dataTestId="form-fname"
        placeholder={i18n.t('wallet-input-place-holder-name')}
      />
      <NameInput
        type="name-loa3"
        name="middle_name"
        onChange={profileInputOnchangeFunc}
        value={name?.middle_name}
        maxLength={150}
        minLength={1}
        label={i18n.t('middle-name')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidMiddleName: e })}
        isFocused={Boolean(name?.middle_name)}
        dataTestId="form-mname"
        placeholder={i18n.t('wallet-input-place-holder-middle-name')}
      />
      <NameInput
        type="name-loa3"
        name="last_name"
        onChange={profileInputOnchangeFunc}
        value={name?.last_name}
        maxLength={150}
        minLength={1}
        label={i18n.t('last-name')}
        onValidate={(e) => setValidateInputs({ ...validInputs, isValidLastName: e })}
        editClick={toggleUpdateNameForm}
        required
        isFocused={Boolean(name?.last_name)}
        dataTestId="form-lname"
        isError={!name?.last_name}
        placeholder={i18n.t('wallet-input-place-holder-last-name')}
      />
      {!name?.last_name && (
        <Text className="error" dataTestId="form-field-error">
          {i18n.t('required')}
        </Text>
      )}
      <div className="btn-group">
        <Button dataTestId="button-cancel" secondary onClick={toggleUpdateNameForm} text={i18n.t('cancel')} />
        <Button dataTestId="button-save" onClick={updateName} text={i18n.t('save')} isLoading={updateProfileLoading} />
      </div>
    </div>
  )
}

export default NameChangeForm
