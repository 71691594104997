import React, { useEffect, useState } from 'react'
import { Button, EmailInput, LinkButton, PasswordInput, Text } from '..'
import parse from 'html-react-parser'
import i18n from '../../i18n'
import { useTriggeredUpdateLogin } from '../../services'
import { InputI, InputValidateI, PageI } from '../ComponentInterface'
import ROUTES from '../../routes/common/constants'
import { ResetStatus } from '../../common/constants/enum'
import { getServices } from '../../wallet/services/ServiceRegistry'

const SignInForm = ({ successFunc, errorObj, setErrorObj }: PageI) => {
  const { useUpdateLogin: updateLogin, isLoading } = useTriggeredUpdateLogin()
  const [inputs, setInputs] = useState<InputI>({ email: '', password: '' })
  const [validInputs, setValidateInputs] = useState<InputValidateI>({
    isValidBtn: false,
  })
  const { storage } = getServices()

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidBtn: Boolean(validInputs.isValidEmail) && Boolean(validInputs.isValidPassword),
    })
    // eslint-disable-next-line
  }, [validInputs.isValidEmail, validInputs.isValidPassword])

  useEffect(() => {
    setErrorObj({
      ...errorObj,
      errorNotification: errorObj.error === 'error-500-1' || errorObj.error.startsWith('error-account-locked') || errorObj.error === 'error-login-too-many-attempts',
      errorPassword: errorObj.error === 'error-login-password',
    })

    // eslint-disable-next-line
  }, [errorObj.error])

  // Check the Enter Button Press
  const keyDownHandler = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      if (validInputs.isValidEmail && validInputs.isValidPassword) {
        submitClick()
      }
    }
  }

  const handleError = (error: any) => {
    if (error.response?.data?.error?.startsWith('account_locked')) {
      const lockedType = error.response.data.error.match(/account_locked_(\w+)$/)[1]
      setErrorObj({ ...errorObj, errorNotification: true, error: `error-account-locked-${lockedType}` })
    } else if (error.isForbiddenError || error.isUnauthorizedError || error.isNotFoundError) {
      setErrorObj({ ...errorObj, errorPassword: true, error: 'error-login-password' })
    } else if (error.isTooManyAttemptError) {
      const timeInSeconds = parseInt(error.response?.data?.error_description.replace(/,/g, ''), 10)
      const minutesRemaining = Math.round(timeInSeconds / 60)
      const hoursRemaining = Math.round(timeInSeconds / 3600)

      let time = timeInSeconds < 3600 ? `${timeInSeconds <= 60 ? '1 minute' : `${minutesRemaining} minutes`}` : `${hoursRemaining <= 1 ? '1 hour' : `${hoursRemaining} hours`}`

      setErrorObj({
        ...errorObj,
        errorNotification: true,
        error: 'error-login-too-many-attempts',
        errorPlaceholderKey: 'remaining_time',
        errorPlaceholderValue: time,
      })
    } else {
      setErrorObj({ ...errorObj, errorNotification: true, error: 'error-500-1' })
    }
  }

  const submitClick = async () => {
    const { email, password } = inputs

    if (!validInputs.isValidEmail || !validInputs.isValidPassword) {
      setErrorObj({ ...errorObj, errorPassword: true, error: 'error-login-password' })
    } else {
      const { response, error } = await updateLogin({
        data: { email, password },
      })
      if (response.isSuccess) {
        storage.set('reset_status', response.data?.status || ResetStatus.NORMAL)
        successFunc && successFunc()
      } else {
        handleError(error)
      }
    }
  }

  return (
    <>
      <form onKeyDown={keyDownHandler} onSubmit={(e) => e.preventDefault()} className="sign-in-form">
        <EmailInput
          onChange={(e: any) => setInputs({ ...inputs, email: e.currentTarget.value })}
          value={inputs.email}
          label={i18n.t('email')}
          onValidate={(e) => {
            setValidateInputs({ ...validInputs, isValidEmail: e })
          }}
          dataTestId="form-email"
          required
          isError={Boolean(errorObj?.errorPassword)}
        />
        <PasswordInput
          minLength={10}
          onChange={(e: any) =>
            setInputs({
              ...inputs,
              password: e.currentTarget.value,
            })
          }
          value={inputs.password}
          label={i18n.t('password')}
          onValidate={(e) => {
            setValidateInputs({
              ...validInputs,
              isValidPassword: e,
            })
          }}
          dataTestId="form-pw"
          required
          isError={Boolean(errorObj?.errorPassword)}
        />
        {errorObj.errorPassword && (
          <Text className="error-msg" dataTestId="error">
            {parse(i18n.t('error-login-password'))}
          </Text>
        )}
        <LinkButton url={`${ROUTES.ForgotPasswordRoute}`} text={i18n.t('forgot-password')} dataTestId="Button-link" />
        <Button onClick={submitClick} text={i18n.t('sign-in')} isLoading={isLoading} dataTestId="Button-primary" />
      </form>
    </>
  )
}

export default SignInForm
