import React from 'react'

import { NotificationI } from './ComponentInterface'

const BoxMessage = ({ icon, text, className, dataTestId }: NotificationI) => (
  <div className={`box-message ${icon ? 'icon' : ''} ${className ? className : ''}`} data-testid={dataTestId}>
    {icon && <i>{icon}</i>}
    {text}
  </div>
)

export default BoxMessage
