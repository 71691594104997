import React from 'react';

import LocalStorage from '../services/LocalStorage';
import WalletProvider from './context/wallet';
import WalletFlowHelperProvider from './context/walletFlowHelper';
import { LanguageProvider } from './context/LanguageContext';
import { useContext } from '../Context';
import wallet from '../services/wallet';

interface DefaultProps {
  children: string | JSX.Element | JSX.Element[];
}

const Providers: React.FC<DefaultProps> = ({ children }) => {
  const {
    state: {
      config: { wallet_config },
    },
  } = useContext();

  const walletInstance = wallet(wallet_config);

  return (
    <LanguageProvider>
      <WalletProvider wallet={walletInstance}>
        <WalletFlowHelperProvider storage={LocalStorage}>{children}</WalletFlowHelperProvider>
      </WalletProvider>
    </LanguageProvider>
  );
};

export default Providers;
