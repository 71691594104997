import React from 'react'
import Spinner from './Spinner'

const Loading = () => {
  return (
    <div className="loading-component">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default Loading
