import React from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

const DeleteAccount = () => {
  return (
    <div className="delete-account">
      <div className="description">{parse(i18n.t('wallet-profile-delete-account'))}</div>
    </div>
  )
}

export default DeleteAccount
