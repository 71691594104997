import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const useRecaptcha = (config: any) => {
	const captchaRef = useRef<ReCAPTCHA>(null);
	const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

	const recaptchaKey = config?.recaptcha_key;

	const asyncScriptOnLoad = () => setRecaptchaLoaded(true);

	const redoCaptcha = () => captchaRef?.current?.reset();

	const getToken = async () => {
		return await captchaRef?.current?.execute();
	};

	return {
		captchaRef,
		recaptchaKey,
		recaptchaLoaded,
		asyncScriptOnLoad,
		getToken,
		redoCaptcha,
	};
};

export default useRecaptcha;
