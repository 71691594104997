import React from 'react'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'
import i18n from '../../i18n'

import ROUTES from '../../routes/common/constants'

import Button from '../buttons/Button'
import Modal from '../Modal'

interface OTPSessionExpiredModalI {
  isModalOpen: boolean
}

const OTPSessionExpiredModal = ({ isModalOpen }: OTPSessionExpiredModalI) => {
  const history = useHistory()

  const handleSignIn = () => {
    history.push(ROUTES.LogoutRoute)
  }

  return (
    <Modal isModalOpen={isModalOpen} canClose={false} className='otp-modal'>
      <div data-testId={'ids-modal-no-acct-session-expired-title'} className={'otp-modal-title'}>
        {parse(i18n.t('otp-session-expired-title'))}
      </div>
      <div data-testId={'ids-modal-no-acct-session-expired-desc'} className={'otp-modal-desc'}>{parse(i18n.t('otp-session-expired-desc'))}</div>
      <div className="logout-btn">
        <Button className={'btn-sign'} onClick={handleSignIn} text={i18n.t('okay')} dataTestId={'okay'} />
      </div>
    </Modal>
  )
}

export default OTPSessionExpiredModal
