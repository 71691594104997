import React from 'react';
import { CtaRowI } from './ComponentInterface';
import { ReactComponent as Success } from '../asset/icon-success-checkmark.svg'
import { ReactComponent as Expired } from '../asset/icon-error.svg'

const CtaRowButton = ({ title, ctaText, onClick, className, index, isExpired }: CtaRowI) => {
    return (
        <div key={index} className={`box-button ${className || ''}`}>
            <span>{title}</span>
            <button onClick={onClick}>

                {
                    isExpired
                    ?
                    <i className="reset-password-success-icon"><Expired /></i>
                    :
                    <i className="reset-password-success-icon"><Success /></i>
                }
                {ctaText}
            </button>
        </div>
    )
}

export default CtaRowButton;
