import React, { FC } from 'react'
import { ReactComponent as Question } from '../asset/icon-question-green.svg'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  value?: string
  action?: string
  onClick?: () => void
  url?: string
  last?: boolean
  hideAction?: boolean
  question?: boolean
  dataTestId?: string
}

const ListItem: FC<Props> = ({ title, value, action, onClick, url, last, hideAction = false, question, dataTestId }) => {
  return (
    <div className={`list-item ${!last ? 'first' : 'last'}`} data-testid={dataTestId}>
      <div className="description">
        <div className="list-title" data-testid="label">
          {title}
        </div>
        <div className="list-value" data-testid="text">
          {value}
        </div>
      </div>
      {!hideAction && (
        <>
          {!!url && !question ? (
            <Link to={url} className="list-action" data-testid={`button-${action?.toLowerCase().split(' ').join('-')}`}>
              {action}
            </Link>
          ) : (
            <div className={`list-action ${question && 'question'}`} data-testid={`button-${action?.toLowerCase().split(' ').join('-')}`} onClick={() => onClick && onClick()}>
              {!question ? action : <Question />}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ListItem
