import React, { useEffect, useState } from 'react'
import i18n from '../../i18n'
import { Layout, Title } from '../../components'
import AppAuthSetupStep2 from '../../components/appAuthSetup/step2'
import { PageI, errorI } from '../../components/ComponentInterface'
import { updateError, useContext } from '../../Context'
import { useTriggeredAuthenticatorRegister } from '../../services'

const AddAuthenticatorAppPage = ({ goBackLink, successFunc }: PageI) => {
  const [authenticatorSecret, setAuthenticatorSecret] = useState('')
  const { useAuthenticatorRegister: authenticatorRegister, isLoading: isQRLoading } = useTriggeredAuthenticatorRegister()
  const { dispatch } = useContext()
  const userEmail = localStorage.getItem('userEmail') ?? undefined

  // error
  const [errorObj, setErrorObj] = useState<errorI>({ error: '' })

  useEffect(() => {
    // Authenticator REgister API Call
    const handleRegisterAuthenticator = async () => {
      try {
        if (!authenticatorSecret && !errorObj.error) {
          const { response, error } = await authenticatorRegister({
            data: { method: 'TOTP' },
          })

          if (response.isSuccess) {
            setAuthenticatorSecret(response?.data?.secret)
          } else if (error.isUnauthorizedError) {
            dispatch(updateError(true))
          } else {
            setErrorObj({
              ...errorObj,
              error: 'error-500-1',
            })
          }
        }
      } catch (e) {
        console.log('e', e)
        setErrorObj({
          ...errorObj,
          error: 'error-500-1',
        })
      }
    }

    handleRegisterAuthenticator()

    // eslint-disable-next-line
  }, [authenticatorSecret, errorObj])

  return (
    <Layout
      header
      footer
      title={i18n.t('setup-authenticator-app-qr-page-title')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="choose-2FA authenticator-app-flow step-2 progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={5}
    >
      <Title title={i18n.t('setup-authenticator-app-qr-page-title')} />
      <AppAuthSetupStep2 authenticatorSecret={authenticatorSecret} isQRLoading={isQRLoading} identifier={userEmail} onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default AddAuthenticatorAppPage
