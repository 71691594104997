export const SessionKeys = {
  SessionWallet: 'session',
}

export const VerificationType = {
  IN_PERSON: 'IN_PERSON',
}

export const ManageAccountError = {
  PASSWORD_INCORRECT: 'error-profile-information-incorrect-password',
  PASSWORD_DO_NOT_MATCH: 'error-profile-information-password-do-not-match',
  PASSWORD_REQUIREMENT: 'error-profile-information-password-requirement',
  INTERNAL_SERVER_ERROR: 'error-500-3',
  ERROR_DATE_OF_BIRTH: 'error-date-of-birth',
  ERROR_PREFERRED_NAME: 'error-preferred-name',
  ERROR_LEGAL_NAME: 'error-legal-name',
  ERROR_ADDRESS: 'error-address',
  ERROR_PHONE_NUMBER: 'error-phone-number',
}

export const SessionManagement = {
  TIME_BEFORE_EXPIRE: 'session_modal',
  TIME_EXPIRATION: 'expiry',
  TIME_MAX_EXPIRATION: 'expiry_max',
  USER_INTERACTION: 'user_interaction',
}
