import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Loading } from '..'
import parse from 'html-react-parser'
import i18n from '../../i18n'
import { useConsent } from '../../services'
import { PageI } from '../ComponentInterface'
import { useContext, updateTermsAccepted } from '../../Context'

const TermsOfServiceForm = ({ successFunc }: PageI) => {
  const {
    dispatch,
    state: {
      userInfo: { terms_accepted },
    },
  } = useContext()
  const { consent, isLoading } = useConsent()
  const [consentData, setConsentData] = useState<{ name: string; identifier: string; isValid?: boolean }[]>([])
  const [isValidBtn, setIsValidBtn] = useState(false)

  useEffect(() => {
    setIsValidBtn(consentData.length > 0 && consentData.every((v: any) => v.isValid))
  }, [consentData])

  useEffect(() => {
    const { data } = consent

    setConsentData(
      data
        ? data.map((consentInfos: any) => {
            const { identifier, name } = consentInfos
            const isValid = terms_accepted.some((term: any) => identifier === term)
            return { ...consentInfos, isValid, checkboxName: name }
          })
        : [],
    )
  }, [consent, terms_accepted])

  const submitClick = async () => {
    const consentIdAccepted = consentData?.map((tosData) => tosData.identifier)
    dispatch(updateTermsAccepted(consentIdAccepted))
    successFunc && successFunc()
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={(e) => e.preventDefault()} className="terms-of-service-form">
          {consentData?.map((tosData: any, i) => (
            <div className="terms-of-service-description-container" key={i}>
              {parse(i18n.t(tosData.name))}
            </div>
          ))}
          {consentData?.map((tosData: any, i) => (
            <div className="terms-of-service-checkbox-container" key={`checkbox-${i}`}>
              <Checkbox
                onChange={() => {
                  consentData[i] = { ...consentData[i], isValid: !consentData[i].isValid }
                  setConsentData([...consentData])
                }}
                label={i18n.t(`${tosData.name}-checkbox`)}
                id={`checkbox-id-${i}`}
                dataTestId={`Checkbox${i}`}
                value={tosData?.isValid}
              />
            </div>
          ))}
          {consentData.length > 0 && <Button text={i18n.t('continue')} onClick={submitClick} disabled={!isValidBtn} dataTestId="Button-primary" />}
        </form>
      )}
    </>
  )
}

export default TermsOfServiceForm
