import axios, { AxiosResponse } from 'axios'

export interface IHeaders {
  [key: string]: string
}

export interface IFetch {
  url: string
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
  headers?: Headers | IHeaders
  data?: any
  params?: {
    [key: string]: string
  }
}

const headers = {
  'Content-Type': 'application/json',
}

const axiosInstance = axios.create({
  headers,
})

const apiFetcher = async <S = any>(options: IFetch): Promise<AxiosResponse<S>> => {
  const { url, method = 'GET', headers, data } = options

  const requestBody = {
    url,
    method,
    headers: {
      ...headers,
    },
    data,
  }

  return await axiosInstance.request(requestBody)
}

export default apiFetcher
