import React from "react";
import { NavLink } from "react-router-dom";

export const ChildMenu = ({ menuData }: { menuData?: any }) => {
	if (!menuData) return null;
	return (
		<>
			{menuData.length > 0 ? (
				<ul className="child-menu-list">
					{menuData.map((menu?: any, index?: number) => (
						<li key={index}>
							<div className="child-menu-list-item">
								{typeof menu.link === "string" ? (
									<NavLink
										className={
											menu.link ? "menu-arrow" : ""
										}
										to={menu.link}
									>
										<div className="left-box">
											{menu.icon && (
												<i className="child-menu-list-item-icon">
													{menu.icon}
												</i>
											)}
											{menu.title && (
												<span
													className={
														!menu.icon
															? "no-icon"
															: ""
													}
												>
													{menu.title}
												</span>
											)}
										</div>
										{menu.rightBox && (
											<div className="right-box">
												{menu.rightBox}
											</div>
										)}
									</NavLink>
								) : (
									<button
										onClick={menu.link}
										className="menu-list-button"
									>
										<div className="left-box">
											{menu.icon && (
												<i className="child-menu-list-item-icon">
													{menu.icon}
												</i>
											)}
											{menu.title && (
												<span
													className={
														!menu.icon
															? "no-icon"
															: ""
													}
												>
													{menu.title}
												</span>
											)}
										</div>
										{menu.rightBox && (
											<div className="right-box">
												{menu.rightBox}
											</div>
										)}
									</button>
								)}
							</div>
							{menu.children && menu.children.length > 0 && (
								<ChildMenu menuData={menu.children} />
							)}
						</li>
					))}
				</ul>
			) : null}
		</>
	);
};

const Menu = ({ data }: { data?: any }) => {
	if (!data) return null;
	return (
		<div className="menu-container">
			<ul>
				{data.map((primaryMenu?: any, index?: number) => (
					<li key={index} className="primary-menu-list">
						<div className="primary-menu-list-item">
							{typeof primaryMenu.link === "string" ? (
								<NavLink
									className={
										primaryMenu.link ? "menu-arrow" : ""
									}
									to={primaryMenu.link}
								>
									<div className="left-box">
										{primaryMenu.icon && (
											<i className="primary-menu-list-item-icon">
												{primaryMenu.icon}
											</i>
										)}
										{primaryMenu.title && (
											<span
												className={
													!primaryMenu.icon
														? "no-icon"
														: ""
												}
											>
												{primaryMenu.title}
											</span>
										)}
									</div>
									{primaryMenu.rightBox && (
										<div className="right-box">
											{primaryMenu.rightBox}
										</div>
									)}
								</NavLink>
							) : (
								<button
									onClick={primaryMenu.link}
									className="menu-list-button"
								>
									<div className="left-box">
										{primaryMenu.icon && (
											<i className="primary-menu-list-item-icon">
												{primaryMenu.icon}
											</i>
										)}
										{primaryMenu.title && (
											<span
												className={
													!primaryMenu.icon
														? "no-icon"
														: ""
												}
											>
												{primaryMenu.title}
											</span>
										)}
									</div>
									{primaryMenu.rightBox && (
										<div className="right-box">
											{primaryMenu.rightBox}
										</div>
									)}
								</button>
							)}
						</div>
						{primaryMenu.children &&
							primaryMenu.children.length > 0 && (
								<ChildMenu menuData={primaryMenu.children} />
							)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Menu;
