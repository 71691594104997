import React, { useEffect } from 'react'
import queryString from 'query-string'

import useHandleLogout from '../../common/hooks/useHandleLogout'
import { Spinner } from '../../components'
import { useLocation } from 'react-router-dom'

const LogoutPage = () => {
  let location: any = useLocation()
  const { logoutMyPei, logoutPhrsaml } = useHandleLogout()

  const { phrsaml }: any = queryString.parse(location?.search)

  const getLogout = async () => {
    if (!phrsaml) {
      logoutMyPei()
    } else {
      logoutPhrsaml()
    }
  }

  useEffect(() => {
    getLogout()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default LogoutPage
