import parse from 'html-react-parser'
import React, { FC, useEffect, useState } from 'react'
import { ReactComponent as CheckIcon } from '../../asset/icon-check-green.svg'
import { ReactComponent as Phone } from '../../asset/phone.svg'
import { Button, IconInfoItem, IconListItem, LinkButton, Modal, Text } from '../../components'
import PhoneInput from '../../components/inputs/PhoneInput'
import { useContext } from '../../Context'
import i18n from '../../i18n'
import { numberRegex, removeDashSpaceSpecialChar, setSpaceIndex } from '../../utils'
import { validatePhoneInput } from '../validate'

type SmsAuthSetupStep1Props = {
  isLoading?: boolean
  errorMessage?: string
  onSubmit: (phoneNumber: string) => void
  onBack?: () => void
}

const SmsAuthSetupStep1: FC<SmsAuthSetupStep1Props> = ({ isLoading, errorMessage = '', onSubmit, onBack }) => {
  const {
    state: {
      userInfo: { profile },
    },
  } = useContext()
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string>(!!profile?.phone_number ? setSpaceIndex(profile.phone_number, [2, 5], 12) : '')
  const toggleModal = () => setModalOpen(!isModalOpen)
  useEffect(() => {
    if (phoneNumber) {
      const isValid = validatePhoneInput(phoneNumber)
      setIsValidPhoneNumber(isValid)
    }
  }, [phoneNumber])
  const PhoneNumberOnChange = (e: any) => {
    if (!numberRegex(removeDashSpaceSpecialChar(e.target.value))) return

    setPhoneNumber(setSpaceIndex(e.target.value, [2, 5], 12))
  }
  return (
    <>
      <IconListItem logo={<Phone />}>
        <div className="setup-sms-text">
          <Text>{parse(i18n.t('setup-sms-description-list-step-1-a'))}</Text>
          <Text>{parse(i18n.t('setup-sms-description-list-step-1-b'))}</Text>
        </div>
      </IconListItem>
      <form onSubmit={(e) => e.preventDefault()} className="phone-form">
        <PhoneInput
          onChange={(e) => {
            PhoneNumberOnChange(e)
          }}
          errorMessage={i18n.t(errorMessage)}
          hasError={!!errorMessage}
          value={phoneNumber}
          label={i18n.t('phone')}
          onValidate={(isValid) => setIsValidPhoneNumber(isValid)}
          required
        />
      </form>
      <IconInfoItem>
        <LinkButton text={i18n.t('learn-about-sms')} onClick={toggleModal} className="add-sms-learn-more" dataTestId="Learn-more" />
      </IconInfoItem>
      <div className="button-container-2">
        <Button secondary text={i18n.t('go-back')} onClick={onBack} dataTestId="Button-secondary" />
        <Button
          text={i18n.t('continue')}
          onClick={() => {
            onSubmit(phoneNumber)
          }}
          dataTestId="Button-primary"
          disabled={!isValidPhoneNumber}
          isLoading={isLoading}
        />
      </div>
      <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} className="authenticator-modal">
        <h2>{`${i18n.t('add-sms-auth-learn-more-modal-title')}`}</h2>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('add-sms-auth-learn-more-modal-desc-1'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('add-sms-auth-learn-more-modal-desc-2'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('add-sms-auth-learn-more-modal-desc-3'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('add-sms-auth-learn-more-modal-desc-4'))}</Text>
        </IconListItem>
      </Modal>
    </>
  )
}

export default SmsAuthSetupStep1
