import axios from 'axios'
import { OpenIdConfigurationResponse } from '../types/wellknown'
import { getServices } from '../services/ServiceRegistry'

const getOpenIdConfiguration = async (): Promise<OpenIdConfigurationResponse> => {
  const { config, storage } = getServices()

  try {
    const cachedResponse = storage.get('well_known_response', true)
    if (cachedResponse) return cachedResponse
    const { data } = await axios.get<OpenIdConfigurationResponse>('/.well-known/openid-configuration', { baseURL: config.ws_url })
    storage.set('well_known_response', data, true)

    return data
  } catch (error: any) {
    throw new Error(error.response)
  }
}

export { getOpenIdConfiguration }
