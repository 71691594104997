import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'
import useConsent from '../../../hooks/useConsent'
import { getServices } from '../../../../../wallet/services/ServiceRegistry'
import { Button } from '../../../../../components'
import useIdvVerification from '../../../hooks/useIdvVerification'

const Verified = () => {
  const [scopeName, setScopeName] = useState({ client: '' })
  const [isLoading, setIsLoading] = useState(false)
  const { storage } = getServices()

  const { getIDVHealthCardLink } = useIdvVerification()

  const transactionID = storage.get('cr_token')
  const { generateClientName } = useConsent(transactionID!)

  useEffect(() => {
    if (!scopeName?.client) {
      setScopeName({ client: generateClientName() })
    }
  }, [scopeName, generateClientName])

  const handleHealthCardRedirection = async () => {
    setIsLoading(true)
    await getIDVHealthCardLink()
  }

  return (
    <div className="wallet-identity-verified">
      <div className="wallet-identity-verified-subtitle" data-testid={'identity-verified-subtitle'}>
        {parse(i18n.t('wallet-identity-verification-verified-desc', { service: scopeName.client }))}
      </div>

      <Button
        className={'wallet-identity-verified-btn'}
        isLoading={isLoading}
        onClick={handleHealthCardRedirection}
        text={i18n.t('wallet-identity-verification-verified-btn')}
        dataTestId={'Button-primary'}
      />
    </div>
  )
}

export default Verified
