type OTPError = 'invalid_otp' | 'expired_otp' | 'too_many_otp_attempts' | 'otp_secret_not_found' | string

type ErrorHandlingResult = {
  message?: string
  isValidPasscode?: boolean
  showLogoutModal?: boolean
}

export const handleSuccessErrorResponse = (response: any, error: any): ErrorHandlingResult => {
  if (response.isSuccess) {
    if (!response.data.error) {
      return { message: undefined }
    }

    const errorActions: Record<OTPError, ErrorHandlingResult> = {
      invalid_otp: { message: 'otp-enter-password-error', isValidPasscode: false },
      expired_otp: { message: 'otp-expired-error', isValidPasscode: false },
      too_many_otp_attempts: { message: 'otp-too-many-attempts-error' },
      otp_secret_not_found: { showLogoutModal: true },
    }

    return (
      errorActions[response.data.error] || {
        message: 'otp-generic-error',
        isValidPasscode: false,
      }
    )
  } else {
    if (error.isUnauthorizedError) {
      return { showLogoutModal: true }
    }
    return { message: 'otp-generic-error', isValidPasscode: false }
  }
}
