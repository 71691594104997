import React from 'react'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'
import i18n from '../../i18n'

import ROUTES from '../../routes/common/constants'

import Button from '../buttons/Button'

const SignInModal = () => {
  const history = useHistory()

  const handleSignIn = () => {
    history.push(ROUTES.LogoutRoute)
  }

  return (
    <div>
      <div data-testId={'session-modal-title'} className={'title'}>
        {parse(i18n.t('session-modal-sgn-title'))}
      </div>
      <div data-testId={'session-modal-desc'}>{parse(i18n.t('session-modal-sgn-desc'))}</div>
      <div className="logout-btn">
        <Button className={'btn-sign'} onClick={handleSignIn} text={i18n.t('sign-in')} dataTestId={'sign-in'} />
      </div>
    </div>
  )
}

export default SignInModal
