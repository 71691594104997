import React, { useState } from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../i18n'

import { Button, Layout, Title } from '../../../../components'
import { getServices } from '../../../../wallet/services/ServiceRegistry'
import useConsent from '../../hooks/useConsent'

const WalletConsentSuccessPage = () => {
  const { storage } = getServices()
  const [isLoading, setIsLoading] = useState(false)
  const transactionID = storage.get('cr_token')
  const { generateClientName } = useConsent(transactionID!)

  const service = generateClientName()

  const handleRedirection = () => {
    setIsLoading(true)
  }

  return (
    <Layout header footer logout>
      <div className="wallet-success-page">
        <Title title={i18n.t('wallet-success-page-title', { service })} dataTestid={'wallet-success-page-title'} />
        <div>{parse(i18n.t('wallet-success-page-desc'))}</div>
        <Button className={'wallet-success-page-btn'} isLoading={isLoading} onClick={handleRedirection} text={i18n.t('wallet-success-page-btn')} dataTestId={'Button-primary'} />
      </div>
    </Layout>
  )
}

export default WalletConsentSuccessPage
