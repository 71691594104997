import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ContextProvider } from './Context'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ContextProvider>
    <App />
  </ContextProvider>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
)

reportWebVitals()
