import parse from 'html-react-parser'
import React, { useEffect } from 'react'
import { Layout, Text, Title } from '../../../components'
import { PageI } from '../../../components/ComponentInterface'
import EmailAuthSetup from '../../../components/emailAuthSetup'
import i18n from '../../../i18n'
import useProfile from '../../Wallet/pages/profile/hooks/useProfile'
import useChange2FA from './hooks/useChange2FA'

const Change2FAEmailPage = ({ goBackLink, successFunc = () => {} }: PageI) => {
  const { profileData, getProfile } = useProfile()
  const { submit, errorObj } = useChange2FA({ method: 'EMAIL' })

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [])
  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back')} title={i18n.t('change-auth-title')} className={'change-2FA email-auth'}>
      <Title title={i18n.t('add-email-auth-title')} />
      {!!errorObj?.error && <Text className="error">{parse(i18n.t(errorObj.error))}</Text>}
      <Text className="withVar lead-text" dataTestId="lead">
        {parse(
          i18n.t('add-email-auth-description-1', {
            var: profileData?.email ? profileData?.email : parse(i18n.t('example-email')),
          }),
        )}
      </Text>
      <EmailAuthSetup
        onSubmit={() => {
          submit({ onSuccess: successFunc })
        }}
        onBack={goBackLink}
      />
    </Layout>
  )
}

export default Change2FAEmailPage
