import parse from 'html-react-parser'
import React, { FC, useState } from 'react'
import { ReactComponent as Step1 } from '../../asset/authenticator-step-1-icon.svg'
import { ReactComponent as CheckIcon } from '../../asset/icon-check-green.svg'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { ReactComponent as LearnMoreIcon } from '../../asset/icon_question.svg'
import { Button, IconListItem, LinkButton, Modal, Text } from '../../components'
import i18n from '../../i18n'
import ROUTES from '../../routes/common/constants'

type AppAuthSetupStep1Props = {
  onSuccess?: () => void
  onBack?: () => void
}

const AppAuthSetupStep1: FC<AppAuthSetupStep1Props> = ({ onSuccess, onBack }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const toggleModal = () => setModalOpen(!isModalOpen)
  return (
    <>
      <IconListItem logo={<Step1 />} dataTestId="step-1">
        <Text>{parse(i18n.t('setup-authenticator-app-step-1'))}</Text>
      </IconListItem>

      <div className="links-container">
        <IconListItem logo={<LearnMoreIcon />} dataTestId="learn-more">
          <LinkButton text={i18n.t('setup-authenticator-app-learn-more')} onClick={toggleModal} dataTestId="Learn-more" />
        </IconListItem>
        <IconListItem logo={<ShowMeIcon />} dataTestId="show-how">
          <LinkButton target="_blank" text={i18n.t('setup-authenticator-app-show-me-link')} url={ROUTES.AccountSetupHelpRoute} dataTestId="Show-how" />
        </IconListItem>
      </div>

      <div className="button-container-2">
        <Button secondary text={i18n.t('setup-authenticator-app-back')} onClick={onBack} dataTestId="Button-secondary" />
        <Button text={i18n.t('continue')} onClick={onSuccess} dataTestId="Button-primary" />
      </div>

      {/* Learn More Modal */}

      <Modal toggleModal={toggleModal} isModalOpen={isModalOpen} className="authenticator-modal">
        <h2>{`${i18n.t('setup-authenticator-app-learn-more-modal-title')}`}</h2>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('setup-authenticator-app-learn-more-modal-desc-1'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('setup-authenticator-app-learn-more-modal-desc-2'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('setup-authenticator-app-learn-more-modal-desc-3'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('setup-authenticator-app-learn-more-modal-desc-4'))}</Text>
        </IconListItem>
        <IconListItem logo={<CheckIcon />}>
          <Text>{parse(i18n.t('setup-authenticator-app-learn-more-modal-desc-5'))}</Text>
        </IconListItem>
      </Modal>
    </>
  )
}

export default AppAuthSetupStep1
