import { getAllDataSources } from '../apis/registry/DataSource'
import { getMyDataSourceAccountsById, getMyDataSourceAccounts } from '../apis/user/DataSource'
import { DataSourceAccountByIdResponse } from '../types/datasource'
import { RegistryDataSourceResponse } from '../types/registry'
import { DataSourceCallback } from '../types/wallet'
import { generateOAuthUrl } from '../utils/OAuthUtils'

type GetAllDataSources = Array<RegistryDataSourceResponse<'RS', true>>
type GetDataSourcesAccountById = DataSourceAccountByIdResponse<{ include_resources: true }>

export const DataSource = () => {
  const createConnectionUrl = async (data_source_id: string, redirect_uri: string) => {
    const { oauthUrl } = await generateOAuthUrl({
      scopePrefix: 'ds',
      identifier: data_source_id,
      responseType: 'none',
      redirect_uri,
    })

    return oauthUrl
  }

  const getDataSources = async (): Promise<GetAllDataSources> => {
    return getAllDataSources('RS', { include_resources: true })!
  }

  const handleCallback = async ({ ds_account_id }: DataSourceCallback): Promise<GetDataSourcesAccountById> => {
    return getMyDataSourceAccountsById(ds_account_id, { include_resources: true })
  }

  return { createConnectionUrl, getDataSources, getMyDataSourceAccounts, handleCallback }
}

export type { GetAllDataSources, GetDataSourcesAccountById }
