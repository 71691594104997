import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import LocalStorage from '../../../../services/LocalStorage'

import { Spinner } from '../../../../components'
import { interpolateRoute } from '../../../../common/utils/interpolateRoute'
import ROUTES from '../../../../routes/common/constants'

const WalletRSCallback = () => {
  const history = useHistory()

  useEffect(() => {
    try {
      var test = LocalStorage.get('cr_token')
      if (!test) throw new Error()
    } catch (e) {
      const token_history = LocalStorage.get('cr_token_history', true) || {}

      const last_key = Object.keys(token_history)[Object.keys(token_history).length - 1]
      LocalStorage.set('cr_token', last_key)
      LocalStorage.set('client', token_history[last_key].client.name)
    }

    history.push(`${interpolateRoute(ROUTES.WalletConsentPage, { transactionID: LocalStorage.get('cr_token')! })}`, {
      replace: true,
    })
  }, [history])

  return (
    <div className="wallet-layout">
      <div className="loading">
        <Spinner dark />
      </div>
    </div>
  )
}

export default WalletRSCallback
