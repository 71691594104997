import React, { useEffect, useState } from 'react'
import { Button, Notification, PasswordInput, Recaptcha, Text } from '..'
import parse from 'html-react-parser'
import i18n from '../../i18n'
import { useContext, updateAccountCreation } from '../../Context'
import { InputI, InputValidateI, PageI } from '../ComponentInterface'
import useSubmitApi from './hooks/useSubmitApi'
import useRecaptcha from './hooks/useRecaptcha'
import useErrorHandling from './hooks/useErrorHandling'
import { getSessionStorage } from '../../utils'

const InPersonAccountCreateForm = ({ successFunc }: PageI) => {
  const {
    state: {
      config,
      userInfo: { terms_accepted, accountCreation },
    },
    dispatch,
  } = useContext()

  const { submitApiCall, isLoading } = useSubmitApi()
  const { getToken, recaptchaKey, recaptchaLoaded, captchaRef, asyncScriptOnLoad, redoCaptcha } = useRecaptcha(config)
  const { handleLocalErrors, error500, errorObj, handleApiError } = useErrorHandling()

  const [inputs, setInputs] = useState<InputI>({
    createPassword: accountCreation?.createPassword ?? '',
    confirmPassword: accountCreation?.confirmPassword ?? '',
  })

  const [validInputs, setValidateInputs] = useState<InputValidateI>({
    isValidBtn: false,
    isValidPassword: !!accountCreation?.createPassword,
  })

  useEffect(() => {
    setValidateInputs({
      ...validInputs,
      isValidBtn: Boolean(inputs.createPassword) && Boolean(inputs.confirmPassword),
    })
    // eslint-disable-next-line
  }, [inputs.createPassword, inputs.confirmPassword])

  const submitClick = async () => {
    try {
      const hasLocalError = handleLocalErrors(inputs, validInputs)
      if (hasLocalError) return

      dispatch(
        updateAccountCreation({
          ...accountCreation,
          createPassword: inputs.createPassword,
          confirmPassword: inputs.confirmPassword,
        }),
      )

      const verification_code = getSessionStorage('createAccountFlow') && JSON.parse(getSessionStorage('createAccountFlow') || '{}')?.verification_code

      const updatedToken = await getToken()
      await submitApiCall(inputs, updatedToken, successFunc, handleApiError, dispatch, terms_accepted, verification_code)
    } catch (e) {
      console.log('e', e)
      error500()
    }
  }

  const handleInputChange = (e: { target: { value: any } }, fieldName: any) => {
    const { value } = e.target
    setInputs((prev) => ({
      ...prev,
      [fieldName]: value,
    }))
  }

  const disabled = () => {
    return !validInputs.isValidBtn || !recaptchaLoaded
  }

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} className="create-id-email-form">
        {errorObj.errorNotification && <Notification text={parse(i18n.t(errorObj.error))} className="error" dataTestId="banner-error" />}

        <div className="textbox-form-container-3">
          <PasswordInput
            minLength={12}
            onChange={(e: any) => {
              handleInputChange(e, 'createPassword')
            }}
            value={inputs.createPassword}
            label={i18n.t('create-password')}
            onValidate={(e) => {
              setValidateInputs({
                ...validInputs,
                isValidPassword: e,
              })
            }}
            dataTestId={errorObj.error.includes('password') ? 'form-pw-error' : 'form-pw'}
            description={parse(i18n.t('password-creation-description'))}
            required
            isError={errorObj.error.includes('password')}
          />
          <PasswordInput
            minLength={12}
            onChange={(e: any) => handleInputChange(e, 'confirmPassword')}
            dataTestId={errorObj.error.includes('password') ? 'form-confirm-pw-error' : 'form-confirm-pw'}
            value={inputs.confirmPassword}
            label={i18n.t('confirm-password')}
            required
            isError={errorObj.error.includes('password')}
          />
        </div>
        {errorObj.error.includes('password') && (
          <Text className="error" dataTestId="error">
            {i18n.t(errorObj.error)}
          </Text>
        )}
        <Button onClick={submitClick} text={i18n.t('continue')} disabled={disabled()} isLoading={isLoading} className="continue" dataTestId="Button-primary" />
        {recaptchaKey && <Recaptcha recaptchaRef={captchaRef} recaptchaKey={recaptchaKey} onChange={redoCaptcha} onExpired={redoCaptcha} asyncScriptOnLoad={asyncScriptOnLoad} />}
      </form>
    </>
  )
}

export default InPersonAccountCreateForm
