import React from "react";
import { ReactComponent as Info } from "../asset/icon_question_black.svg";
import { IconInfoItemI } from "./ComponentInterface";

const IconInfoItem = ({ children }: IconInfoItemI) => (
	<div className="icon-info-item">
		<i>
			<Info />
		</i>
		{children}
	</div>
);

export default IconInfoItem;
