import { getClientById } from '../apis/registry/EnrolledClients'
import { getMyDataSourceAccounts } from '../apis/user/DataSource'
import { getTxCancelById, getTxPermissionsById, getTxReclaimUserById, getTxRedirectById, getTxUserById } from '../apis/user/Transactions'
import { CreatePermission } from '../types/permission'
import { RequestedResource, TransactionResponse } from '../types/transaction'
import { ConsentRequestCallback } from '../types/wallet'
import { matchAndValidatePermissions } from '../utils/ConsentRequestUtilityHelpers'

import { getServices } from './ServiceRegistry'

export const ConsentRequest = () => {
  const { storage } = getServices()

  const handleCallback = async (token: ConsentRequestCallback['token'], client: ConsentRequestCallback['client']): Promise<TransactionResponse | null | undefined> => {
    const isAuthenticated = storage.get('session')

    storage.set('cr_token', token)
    storage.set('client', client)

    if (isAuthenticated) {
      try {
        const transaction = await getTxUserById(token)

        return transaction
      } catch (error: any) {
        const { status } = error

        if (status === 403) {
          const transaction = await getTxReclaimUserById(token)

          return transaction
        }
        if (status === 401) {
          const sp = await getClientById(client)
          storage.set('sp_info', sp, true)
          return null
        }
      }
    } else {
      const sp = await getClientById(client)
      storage.set('sp_info', sp, true)

      return null
    }
  }

  const cancel = async (transaction_id: string) => {
    return await getTxCancelById(transaction_id)
  }

  const createPermissions = async (requested_resources: RequestedResource[]) => {
    let ds_accounts = await getMyDataSourceAccounts({ include_resources: true, include_disabled: false })

    const currentDate = new Date()
    ds_accounts = ds_accounts.filter((item) => new Date(item.rot.expires_at) > currentDate)

    const permissions = matchAndValidatePermissions(requested_resources, ds_accounts)

    return permissions
  }

  const submitPermissions = async (transaction_id: string, create_permissions: Array<CreatePermission>) => {
    const { permission_code } = await getTxPermissionsById(transaction_id, create_permissions)

    return await claimPermission(transaction_id, permission_code)
  }

  const claimPermission = async (transaction_id: string, permission_code: string) => {
    storage.delete('client')
    storage.delete('sp_info')
    storage.delete('cr_token')
    storage.delete('cr_resource_definition')

    const res = await getTxRedirectById(transaction_id, permission_code)

    return res
  }

  /**
   * checks if consent-request has been deferred i.e. User is relogging to complete their consent-request
   */
  const isDeferred = !!storage.get('sp_info')

  return { handleCallback, submitPermissions, createPermissions, cancel, isDeferred }
}
