import { GrantedPermissions } from '../..'
import { EnrolledClientPermissionQueryFlags } from '../../types/enrolled-client'
import { makeAuthenticatedApiCall } from '../../utils/ApiHelper'
import { buildQueryString } from '../../utils/BuildQueryString'

const URL_ENROLLED_CLIENT = '/me/enrolled_client'

export const revokeMyEnrolledClientPermissionsById = (enrolled_client_id: string) =>
  makeAuthenticatedApiCall<GrantedPermissions[]>('POST', `${URL_ENROLLED_CLIENT}/${enrolled_client_id}/permissions/disable`)

/**
 * Retrieves permissions by a enrolled_client id for an authenticated user with optional query flags to filter the response.
 * @param {Flags} flags - An object containing query flags:
 *  - `notDisabled`: flag to decide if revoked permissions should be included in result e.g. when set to `true` revoked permissions are not returned
 *  - `notExpired`:  flag to decide if expired permissions should be included in result e.g. when set to `true` expired permissions are not returned
 */
export const getMyEnrolledClientPermissionsById = (enrolled_client_id: string, flags?: EnrolledClientPermissionQueryFlags) => {
  const queryParams = buildQueryString(flags || {})
  return makeAuthenticatedApiCall<GrantedPermissions[]>('GET', `${URL_ENROLLED_CLIENT}/${enrolled_client_id}/permissions${queryParams}`)
}
