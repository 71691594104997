import { IWalletClientConfig, IWalletClientStorage } from '..'

let globalConfig: IWalletClientConfig | null = null
let globalStorage: IWalletClientStorage | null = null

export const configureServices = (config: IWalletClientConfig, storage: IWalletClientStorage) => {
  globalConfig = config
  globalStorage = storage
}

export const getServices = () => {
  if (!globalConfig || !globalStorage) {
    throw new Error('Services are not properly configured')
  }
  return {
    config: globalConfig,
    storage: globalStorage,
  }
}
