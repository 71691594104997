import React, { useEffect, useState } from 'react'

import parse from 'html-react-parser'
import { ReactComponent as ShowMeIcon } from '../../asset/icon-show-me.svg'
import { ReactComponent as QuestionIcon } from '../../asset/icon-question-green.svg'
import { IconListItem, LinkButton, PasscodeInput, Spinner, Text } from '..'

import i18n from '../../i18n'
import ROUTES from '../../routes/common/constants'
import { useTriggeredUpdateOTP, useTriggeredUpdateOTPResend } from '../../services'
import CantAccessAppModal from '../CantAccessAppModal'
import { PasscodeFormI } from '../ComponentInterface'
import OTPSessionExpiredModal from '../session/OTPSessionExpiredModal'
import { handleSuccessErrorResponse } from '../../utils/otpHandleSuccessErrorResponse'

const PasscodeForm = ({ successFunc, apiType, twoFaMethod }: PasscodeFormI) => {
  const { useUpdateOTP: updateOtp, isLoading: isLoadingOtp } = useTriggeredUpdateOTP()
  const { useUpdateOTPResend: updateOtpResend, isLoading: isLoadingOtpResend } = useTriggeredUpdateOTPResend()
  const [passcode, setPasscode] = useState('')
  const [isValidPasscode, setIsValidPasscode] = useState(false)
  const [message, setMessage] = useState('')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [error, setError] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const [showCantAccessAppModal, setShowAccessAuthAppModal] = useState(false)

  useEffect(() => {
    if (!isValidPasscode) return
    const verifyPasscodeOTP = async () => {
      // login account use apiType 'post'
      // create account use apiType 'createAccount'
      const { response, error } = await updateOtp({
        data: { otp: passcode },
      })
      await successErrorResponse(response, error)
    }
    verifyPasscodeOTP()
    // eslint-disable-next-line
  }, [isValidPasscode])

  useEffect(() => {
    const messageText = ['otp-resend-fail', 'otp-generic-error', 'otp-enter-password-error', 'otp-too-many-attempts-error', 'otp-expired-error']
    setError(messageText.some((text) => message.includes(text)))
  }, [message])

  const successErrorResponse = async (response: any, error: any) => {
    const result = handleSuccessErrorResponse(response, error)

    if (result.showLogoutModal) {
      setShowLogoutModal(true)
    }

    if (result.message !== undefined) {
      setMessage(result.message)
    }

    if (result.isValidPasscode !== undefined) {
      setIsValidPasscode(result.isValidPasscode)
    }

    if (response.isSuccess && !response.data.error) {
      setIsRedirecting(true)
      successFunc && successFunc()
    }
  }

  const resendClick = async () => {
    const { response } = await updateOtpResend()
    if (response.isSuccess) {
      setMessage('otp-resend-success')
    } else {
      setMessage('otp-resend-fail')
    }
  }

  return (
    <>
      {isLoadingOtp || isLoadingOtpResend || isRedirecting ? (
        <Spinner dark />
      ) : (
        <>
          <PasscodeInput
            onChange={(e: any) => {
              setPasscode(e)
            }}
            onValidate={setIsValidPasscode}
            label={i18n.t('one-time-password-title')}
            className={error ? 'error' : ''}
            code={message ? passcode : ''}
          />
          {error && (
            <Text className="error-msg" dataTestId="error">
              {parse(i18n.t(twoFaMethod === 'EMAIL' ? `${message}-passcode` : message))}
            </Text>
          )}
          {twoFaMethod === 'TOTP' ? (
            <div className="links-container">
              <IconListItem logo={<ShowMeIcon />} dataTestId="show-how">
                <LinkButton text={i18n.t('setup-authenticator-app-show-me-link')} url={ROUTES.AccountSetupHelpRoute} target="_blank" dataTestId="Show-how" />
              </IconListItem>
              <IconListItem logo={<QuestionIcon />}>
                <LinkButton text={i18n.t('one-time-password-totp-cant-access')} onClick={() => setShowAccessAuthAppModal(true)} dataTestId="cant-access" />
                <CantAccessAppModal isOpen={showCantAccessAppModal} onClose={() => setShowAccessAuthAppModal(false)} />
              </IconListItem>
            </div>
          ) : (
            <>
              {message === 'otp-resend-success' && !error && (
                <Text className="code-sent" dataTestId="body-resend">
                  {i18n.t(message)}
                </Text>
              )}
              <LinkButton className="code-sent-text" onClick={resendClick} text={(i18n.t(twoFaMethod === 'EMAIL' ? 'one-time-password-resend-passcode' : 'one-time-password-resend-verification'))} dataTestId="Button-link" />
            </>
          )}
        </>
      )}
      {<OTPSessionExpiredModal isModalOpen={showLogoutModal} />}
    </>
  )
}

export default PasscodeForm
