import { ResetStatus } from '../../common/constants/enum'
import { EnrolledClient } from './enrolled-client'
import { TransactionResponse } from './transaction'
import { OpenIdConfigurationResponse } from './wellknown'

// Config
interface IWalletClientConfig {
  ws_url: string
  ws_api_url: string
  client_id: string
  idp: string
}

// Storage
interface IWalletStorage {
  well_known_response: OpenIdConfigurationResponse
  oauth_state: string
  code_verifier: string
  session: string
  session_expiry_date: string
  session_expiry_token: string
  redirect_uri: string
  cr_token: string
  client: string
  cr_resource_definition: string
  cr_token_history: Record<string, TransactionResponse>
  sp_info: EnrolledClient
  idp_after_type: any
  idp_last_view_name: any
  session_current_page: string
  auto_consent: boolean
  reset_status: ResetStatus
}

type WalletStorageMap = keyof IWalletStorage

interface StorageCrud<K extends WalletStorageMap> {
  /**
   * Retrieves a value from the storage.
   *
   * @template T The specific key type to retrieve.
   * @param key The key to retrieve the value for.
   * @param isObject Whether the value is an object.
   * @returns The value associated with the key, or `null` if not found.
   */
  get: <T extends K>(key: T, isObject?: boolean) => IWalletStorage[T] | null
  /**
   * Stores a value in the storage.
   *
   * @param key The key to store the value under.
   * @param value The value to store.
   * @param isObject Whether the value is an object.
   */
  set: (key: K, value: IWalletStorage[K], isObject?: boolean) => void
  /**
   * Removes a value from the storage.
   *
   * @param key The key of the value to remove.
   */
  delete: (key: K) => void
}

type IWalletClientStorage = StorageCrud<WalletStorageMap>

export enum EIDVBackBtn {
  PROFILE = 'PROFILE',
  CONSENT_IDENTITY = 'CONSENT_IDENTITY',
}

// Wallet Server Callbacks
export enum ECallbackError {
  invalid_sessin = 'invalid_session',
}
interface TokenCallback {
  code: string
  state: string
  error?: ECallbackError
  autoConsent?: boolean
}
interface DataSourceCallback {
  data_source_id?: string
  ds_account_id: string
}

interface ConsentRequestCallback {
  token: string
  client: string
  resources?: string
  autoConsent?: boolean
}

export type { IWalletClientConfig, TokenCallback, DataSourceCallback, ConsentRequestCallback, WalletStorageMap, StorageCrud, IWalletClientStorage }
