import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateProfileState, useContext } from '../../Context'
import { Layout, Title } from '../../components'
import { PageI } from '../../components/ComponentInterface'
import SmsAuthSetupStep1 from '../../components/smsAuthSetup/step1'
import i18n from '../../i18n'
import ROUTES from '../../routes/common/constants'
import { useTriggeredAuthenticatorRegister } from '../../services'

const AddSMSAuthPage = ({ successFunc, goBackLink }: PageI) => {
  const {
    state: {
      userInfo: { profile },
    },
    dispatch,
  } = useContext()

  const { useAuthenticatorRegister: authenticatorRegister, isLoading } = useTriggeredAuthenticatorRegister()
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const submitClick = async (phoneNumber: string) => {
    try {
      dispatch(updateProfileState({ ...profile, phone_number: phoneNumber }))
      const rawPhoneNumber = phoneNumber.replace(/[\s\-–—]+/g, '') // remove space/any kind of hyphen from phoneNumber
      const { response, error } = await authenticatorRegister({
        data: { method: 'SMS', phone_number: rawPhoneNumber },
      })
      successErrorResponse(response, error)
    } catch (e) {
      console.log('e', e)
    }
  }

  const successErrorResponse = (response: any, error: any) => {
    if (response.isSuccess) {
      successFunc?.()
      return
    }
    switch (true) {
      case error.isUnauthorizedError:
        history.push(ROUTES.LogoutRoute)
        break
      case error.isInternalServerError:
        setErrorMessage('phone-number-server-error')
        break
      case error.isInvalidError:
        setErrorMessage('invalid-phone-number-error')
        break
      default:
        break
    }
  }

  return (
    <Layout
      header
      footer
      title={i18n.t('setup-sms-auth')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="choose-2FA add-sms progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={4}
    >
      <Title title={i18n.t('setup-sms-title')} />
      <SmsAuthSetupStep1 onSubmit={submitClick} onBack={goBackLink} errorMessage={errorMessage} isLoading={isLoading} />
    </Layout>
  )
}

export default AddSMSAuthPage
