import React from 'react'
import parse from 'html-react-parser'

import iconArrow from '../asset/icon-table-arrow.svg'
import i18n from '../i18n'

interface Props {
  children: string | JSX.Element | JSX.Element[]
}

interface PropsCard {
  id: string
  title: string
  type: string
  lastActive: string
  disabled?: boolean
  onClickRow: () => void
}

export const Card = ({ id, title, type, lastActive, onClickRow, disabled }: PropsCard) => {
  return (
    <span className="card-view-container" onClick={() => onClickRow()}>
      <div key={`org-${id}`} className="org-container">
        {disabled && <div className="disabled">{parse(i18n.t('wallet-consent-history-details-revoked'))}</div>}
        <div className="who">{title}</div>
        <div className="type">{type}</div>
        <div className="lastActive">{lastActive}</div>
      </div>
      <div key={`arrow-${id}`} className="arrow">
        <img src={iconArrow} alt="select the service" />
      </div>
    </span>
  )
}

const TableCard = ({ children }: Props) => {
  return <div className="table-card-view">{children}</div>
}

export default TableCard
