import { createContext, useContext } from 'react'

import ConsentRequestTokenHistory from '../../services/ConsentRequestManager'

interface WalletFlowHellperCtxDefaultValueProps {
  ConsentRequestTokenHistory: ReturnType<typeof ConsentRequestTokenHistory>
}

export const WalletFlowHelperContext = createContext<WalletFlowHellperCtxDefaultValueProps | undefined>(undefined)

export const useWalletFlowHelper = () => {
  const context = useContext(WalletFlowHelperContext)

  if (!context) {
    throw new Error('App tree is missing wallet provider')
  }
  return context
}
