import React, { useMemo } from 'react'

import { WalletContext } from '../../context/wallet'

export interface WallerProviderProps {
  children: React.ReactNode
  wallet: any
}

const WalletProvider = ({ children, wallet }: WallerProviderProps) => {
  const providerValue = useMemo(() => ({ ...wallet }), [wallet])

  return <WalletContext.Provider value={providerValue}>{children}</WalletContext.Provider>
}

export default WalletProvider
