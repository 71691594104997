import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import i18n from '../../../../../i18n'

import useConsent from '../../../hooks/useConsent'
import { getServices } from '../../../../../wallet/services/ServiceRegistry'
import { Button } from '../../../../../components'
import useIdvVerification from '../../../hooks/useIdvVerification'

const Pending = () => {
  const [scopeName, setScopeName] = useState({ client: '' })

  const { storage } = getServices()

  const transactionID = storage.get('cr_token')
  const { generateClientName } = useConsent(transactionID!)
  const { handleInPerson } = useIdvVerification()

  useEffect(() => {
    if (!scopeName?.client) {
      setScopeName({ client: generateClientName() })
    }
  }, [scopeName, generateClientName])

  return (
    <div className="wallet-identity-unverified">
      <div className="wallet-identity-unverified-subtitle" data-testid={'pending-subtitle'}>
        {parse(i18n.t('wallet-identity-verification-pending-desc-1', { service: scopeName.client }))}
      </div>

      <div className="wallet-identity-pending-dec" data-testid={'pending-desc'}>
        {parse(i18n.t('wallet-identity-verification-pending-desc-2'))}
      </div>

      <div className="wallet-identity-unverified-desc" data-testid={'pending-desc-2'}>
        {parse(i18n.t('wallet-identity-verification-pending-desc-3'))}
      </div>

      <Button
        className={'wallet-identity-unverified-btn2'}
        isLoading={false}
        onClick={handleInPerson}
        text={i18n.t('wallet-identity-verification-unverified-btn-2')}
        dataTestId={'Button-primary'}
      />
    </div>
  )
}

export default Pending
