import React from 'react'
import { Layout, Title } from '../../components'
import i18n from '../../i18n'

import { PageI } from '../../components/ComponentInterface'
import AppAuthSetupStep1 from '../../components/appAuthSetup/step1'

const SetupAuthenticatorAppPage = ({ successFunc, goBackLink }: PageI) => {
  return (
    <Layout
      header
      footer
      title={i18n.t('setup-authenticator-app-title')}
      backBtnCallback={goBackLink}
      backText={i18n.t('back')}
      className="choose-2FA authenticator-app-flow step-1 progress-bar"
      stepper
      stepperLabel={i18n.t('account-creation')}
      stepperNumberofSteps={6}
      stepperCurrentStep={4}
    >
      <Title title={i18n.t('setup-authenticator-app-title')} />
      <AppAuthSetupStep1 onSuccess={successFunc} onBack={goBackLink} />
    </Layout>
  )
}

export default SetupAuthenticatorAppPage
