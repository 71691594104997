import parse from 'html-react-parser'
import React, { FC } from 'react'
import { Button, LinkButton, Text } from '../../components'
import i18n from '../../i18n'

type EmailAuthSetupProps = {
  isLoading?: boolean
  onSubmit?: () => void
  onBack?: () => void
}

const EmailAuthSetup: FC<EmailAuthSetupProps> = ({ isLoading, onSubmit, onBack }) => {
  return (
    <>
      <Text>{parse(i18n.t('add-email-auth-description-2'))}</Text>
      <Text>{parse(i18n.t('add-email-auth-description-3'))}</Text>
      <div className="button-container-2">
        <Button text={i18n.t('continue')} onClick={onSubmit} isLoading={isLoading} className="continue" dataTestId="Button-primary" />
      </div>
      <LinkButton onClick={onBack} text={i18n.t('choose-different-auth')} dataTestId="Button-link" />
    </>
  )
}

export default EmailAuthSetup
