import React from 'react';
import {SpinnerI} from './ComponentInterface'

const Spinner = ({dark}: SpinnerI) => {
    return (
        <div className={`lds-spinner-container ${dark ? 'dark': ''}`}>
            <div className="lds-spinner">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>

        </div>
    )
}

export default Spinner;
