import React from 'react'
import parse from 'html-react-parser'

import i18n from '../../../../../i18n'

import { Modal } from '../../../../../components'

export enum KeyDefinitionType {
  history = 'history',
  service = 'service',
  information = 'information',
}
interface Props {
  onClose: () => void
  isOpen: boolean
  type: string
}

const InfoByType = ({ type }: { type: string }) => {
  switch (type) {
    case KeyDefinitionType.information:
      return (
        <>
          <div className="subtitle" data-testid={'key-subtitle'}>
            {parse(i18n.t('wallet-consent-history-key-def-information-subtitle'))}
          </div>
          <div className="subtitle" data-testid={'key-information'}>
            {parse(i18n.t('wallet-consent-history-key-def-information'))}
          </div>
          <div className="subtitle" data-testid={'key-authorized'}>
            {parse(i18n.t('wallet-consent-history-key-def-authorized'))}
          </div>
          <div className="subtitle" data-testid={'key-expired'}>
            {parse(i18n.t('wallet-consent-history-key-def-expired'))}
          </div>
        </>
      )
    case KeyDefinitionType.service:
      return (
        <>
          <div className="subtitle" data-testid={'key-subtitle'}>
            {parse(i18n.t('wallet-consent-history-key-def-service-subtitle'))}
          </div>
          <div className="subtitle" data-testid={'key-information'}>
            {parse(i18n.t('wallet-consent-history-key-def-information'))}
          </div>
          <div className="subtitle" data-testid={'key-source2'}>
            {parse(i18n.t('wallet-consent-history-key-def-source2'))}
          </div>
          <div className="subtitle" data-testid={'key-expired'}>
            {parse(i18n.t('wallet-consent-history-key-def-expired'))}
          </div>
        </>
      )

    default:
      return (
        <>
          <div className="subtitle" data-testid={'key-subtitle'}>
            {parse(i18n.t('wallet-consent-history-key-def-subtitle'))}
          </div>
          <div className="subtitle" data-testid={'key-who'}>
            {parse(i18n.t('wallet-consent-history-key-def-who'))}
          </div>
          <div className="subtitle" data-testid={'key-type'}>
            {parse(i18n.t('wallet-consent-history-key-def-type'))}
          </div>
          <div className="subtitle" data-testid={'key-last'}>
            {parse(i18n.t('wallet-consent-history-key-def-last'))}
          </div>
          <div className="subtitle" data-testid={'key-service'}>
            {parse(i18n.t('wallet-consent-history-key-def-service'))}
          </div>
          <div className="subtitle" data-testid={'key-source'}>
            {parse(i18n.t('wallet-consent-history-key-def-source'))}
          </div>
        </>
      )
  }
}

const KeyDefinitionModal = ({ onClose, isOpen, type }: Props) => {
  return (
    <Modal toggleModal={onClose} isModalOpen={isOpen} className="wallet-history-key-definition">
      <div data-testid={'key-definition-modal'}>
        <div className="title" data-testid={'key-title'}>
          {parse(i18n.t('wallet-consent-history-key-def-title'))}
        </div>
        <InfoByType type={type} />
      </div>
    </Modal>
  )
}

export default KeyDefinitionModal
