import React from "react";
import { Title, TitleH3, Text, Layout, LinkButton } from "../../components";
import i18n from '../../i18n';
import { PageI } from '../../components/ComponentInterface';
import { useContext } from '../../Context';

const DriverLicenceCardProfileInformationPage = ({ successFunc, goBackLink }: PageI) => {
  const { state } = useContext();

  return (
    <Layout header footer backBtnCallback={goBackLink} backText={i18n.t('back-to-verification-info')} title={i18n.t('identity-verification-driver-licence-card-verification-title')} className={'identity-profile-information'} logout >

      <Title title={i18n.t('identity-verification-driver-licence-card-verification-title')} />

      <div className="sub-header-section">
        <TitleH3 title={i18n.t('identity-verification-saved-information')} />
        <LinkButton text={i18n.t('edit')} onClick={successFunc} className="edit-btn" /> 
      </div>

      <Text className="sub-title-description">{i18n.t('identity-verification-saved-information-description')}</Text>

      <div className="details-container">
        <div className="item">
          <Text className="label">{i18n.t('name')}</Text>
          <Text className="detail">{state.userInfo?.profile?.first_name}</Text>
        </div>

        <div className="item">
          <Text className="label">{i18n.t('last-name')}</Text>
          <Text className="detail">{state.userInfo?.profile?.last_name}</Text>
        </div>
        
        <div className="item">
          <Text className="label">{i18n.t('driver-licence-number')}</Text>
          <Text className="detail">{state.userInfo?.profile?.driver_license_number}</Text>
        </div>

        <div className="item">
          <Text className="label">{i18n.t('expiry-date')}</Text>
          <Text className="detail">{state.userInfo?.profile?.expiry_date_driver_license}</Text>
        </div>

        <div className="item">
          <Text className="label">{i18n.t('serial')}</Text>
          <Text className="detail">{state.userInfo?.profile?.serial_number_drivers_license}</Text>
        </div>

        <div className="item">
          <Text className="label">{i18n.t('date-of-birth')}</Text>
          <Text className="detail">{state.userInfo?.profile?.birthdate}</Text>
        </div>

      </div>
     
    </Layout>
  )
}

export default DriverLicenceCardProfileInformationPage;
