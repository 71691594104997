import React from "react";
import i18n from "../../i18n";
import IconButton from "../buttons/IconButton";
import { ReactComponent as Info } from "../../asset/icon-question.svg";
import { ReactComponent as Error } from "../../asset/icon-input-error.svg";
import { validateEmail } from "../validate";
import { EmaiInputI } from "../ComponentInterface";

const EmailInput = ({
	value,
	onChange,
	optional,
	label,
	infoClick,
	onValidate,
	required,
	isError,
	dataTestId,
}: EmaiInputI) => {
	const onEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) onChange(e);
		if (onValidate) onValidate(validateEmail(e));
	};
	return (
		<div className="textbox-form email-input">
			<label
				htmlFor={label || "email"}
				className={`${!label ? "visuallyhidden" : ""}`}
			>
				{label || "email"}
				{optional && <span>{`${i18n.t("optional")}`}</span>}
				{required && " *"}
			</label>
			<div
				className={`textbox-form-input ${
					infoClick ? "textbox-form-input-info" : ""
				} ${isError ? "error" : ""}`}
			>
				<input
					onChange={onEmailInput}
					type="email"
					value={value}
					id={`${label}-1` || "email-address"}
					formNoValidate
					data-testid={dataTestId}
				/>
				{infoClick && (
					<IconButton onClick={infoClick} icon={<Info />} />
				)}
				{isError && !infoClick && <div className="error-icon-container"><Error className="error-icon" /></div>}
			</div>
		</div>
	);
};

export default EmailInput;
